import {
  ADD_PLATFORM_NICKNAME_FAILURE,
  ADD_PLATFORM_NICKNAME_REQUEST,
  ADD_PLATFORM_NICKNAME_SUCCESS,
  PLATFORM_NICKAMES_LIST_FAILURE,
  PLATFORM_NICKAMES_LIST_SUCCESS,
  UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_FAILURE,
  UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_REQUEST,
  UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_SUCCESS,
  UPDATE_PLATFORM_NICKNAME_FAILURE,
  UPDATE_PLATFORM_NICKNAME_REQUEST,
  UPDATE_PLATFORM_NICKNAME_SUCCESS,
} from "../../../constants";

const initialState = {
  loading: true,
  updateNicknameLoading: false,
  platformNicknamesList: {
    data: [],
    columns: [
      {
        field: "platformDs",
        title: "label.player.platformNicknames.gameDomain",
        editable: false,
        default: true,
      },
      {
        field: "nickname",
        title: "label.player.platformNicknames.nickname",
        default: true,
        editComponentDef: {
          name: "nickname",
          labelPrefix: "label.player.platformNicknames.",
          type: "Text",
        },
      },
    ],
  },
};

const PlatformNicknamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PLATFORM_NICKNAME_SUCCESS:
    case PLATFORM_NICKAMES_LIST_SUCCESS:
    case UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_SUCCESS: {
      return {
        ...state,
        loading: false,
        platformNicknamesList: {
          ...state.platformNicknamesList,
          data: action.payload,
        },
      };
    }
    case PLATFORM_NICKAMES_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
        platformNicknamesList: {
          ...state.platformNicknamesList,
          data: null,
        },
      };
    }
    case UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_FAILURE:
    case ADD_PLATFORM_NICKNAME_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_PLATFORM_NICKNAME_REQUEST:
    case UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_PLATFORM_NICKNAME_REQUEST: {
      return {
        ...state,
        updateNicknameLoading: true,
      };
    }
    case UPDATE_PLATFORM_NICKNAME_SUCCESS: {
      return {
        ...state,
        updateNicknameLoading: false,
      };
    }
    case UPDATE_PLATFORM_NICKNAME_FAILURE: {
      return {
        ...state,
        updateNicknameLoading: false,
      };
    }
    default:
      return state;
  }
};

export default PlatformNicknamesReducer;
