import React from "react";
import Moment from "react-moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import Translate from "../../../../Layouts/Translator/Translate";
import {
  GRANULARITY_TYPE_FAILURE,
  GRANULARITY_TYPE_INFO_FAILURE,
  GRANULARITY_TYPE_INFO_SUCCESS,
  GRANULARITY_TYPE_SUCCESS,
  PROGRAM_DETAIL_FAILURE,
  PROGRAM_DETAIL_REQUEST,
  PROGRAM_DETAIL_SUCCESS,
  PROGRAMS_FAILURE,
  PROGRAMS_REQUEST,
  PROGRAMS_SUCCESS,
  QUALIFICATION_PERIOD_FAILURE,
  QUALIFICATION_PERIOD_SUCCESS,
} from "../../../constants";
import { Box } from "@material-ui/core";
import { FormattedNumber } from "react-intl";

const getStatusMapping = (status) => {
  switch (status) {
    case "DRAFT":
      return { id: "1", label: "draft" };
    case "SUSPENDED":
      return { id: "3", label: "suspended" };
    case "ACTIVE":
      return { id: "2", label: "active" };
    case "CANCELED":
      return { id: "6", label: "canceled" };
    case "EXPIRED":
      return { id: "6", label: "expired" };
    case "ERROR":
      return { id: "4", label: "error" };
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

export const labelPrefix = "label.rewards.loyalty.program.";

const initialState = {
  programs: {
    programsLoading: true,
    programsData: [],
    columns: [
      {
        field: "programId",
        title: labelPrefix + "programId",
        defaultSort: "asc",
      },
      {
        field: "name",
        title: labelPrefix + "name",
      },
      {
        field: "status",
        title: labelPrefix + "status",
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapped = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapped.id}
                label={statusMapped.label}
                justify={"center"}
              />
            );
          }
        },
      },
      {
        field: "activationDate",
        title: labelPrefix + "activationDate",
        render: (rowData) => {
          return <Moment format={"L"}>{rowData["activationDate"]}</Moment>;
        },
      },
      {
        field: "lastUpdate",
        title: labelPrefix + "lastUpdate",
        render: (rowData) => {
          return <Moment format={"L"}>{rowData["lastUpdate"]}</Moment>;
        },
      },
      {
        field: "expirationDate",
        title: labelPrefix + "expirationDate",
        render: (rowData) => {
          return <Moment format={"L"}>{rowData["expirationDate"]}</Moment>;
        },
      },
      {
        field: "defaultOptin",
        title: labelPrefix + "defaultOptin",
        render: (rowData) => {
          return (
            <YesNoBadge value={rowData["defaultOptin"]} justify={"center"} />
          );
        },
      },
    ],
  },
  programDetail: {
    data: null,
    detailLoading: true,
    fields: [
      {
        field: "name",
        title: labelPrefix + "name",
      },
      {
        field: "description",
        title: labelPrefix + "description",
      },
      {
        field: "status",
        title: labelPrefix + "status",
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapping = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus id={statusMapping.id} label={statusMapping.label} />
            );
          }
        },
      },
      {
        field: "defaultOptin",
        title: labelPrefix + "defaultOptin",
        render: (rowData) => {
          return <YesNoBadge value={rowData["defaultOptin"]} />;
        },
      },
      {
        field: "activationDate",
        title: labelPrefix + "activationDate",
        render: (rowData) => {
          if (rowData["activationDate"]) {
            return <Moment format="L LT">{rowData["activationDate"]}</Moment>;
          }
        },
      },
      {
        field: "expirationDate",
        title: labelPrefix + "expirationDate",
        render: (rowData) => {
          if (rowData["expirationDate"]) {
            return <Moment format="L LT">{rowData["expirationDate"]}</Moment>;
          }
        },
      },

      {
        field: "lastUpdate",
        title: labelPrefix + "lastUpdate",
        render: (rowData) => {
          if (rowData["lastUpdate"]) {
            return <Moment format="L LT">{rowData["lastUpdate"]}</Moment>;
          }
        },
      },
      {
        field: "suspensionDate",
        title: labelPrefix + "suspensionDate",
        hidden: (rowData) => rowData["status"] !== "SUSPENDED",
        render: (rowData) => {
          if (rowData["suspensionDate"]) {
            return <Moment format="L LT">{rowData["suspensionDate"]}</Moment>;
          }
        },
      },
    ],
    domainDetailLoading: false,
    domainDetailFields: [
      {
        field: "description",
        title: labelPrefix + "description",
      },
      {
        field: "levelRulePluginId",
        title: labelPrefix + "levelRulePluginId",
        render: (rowData) => {
          return (
            <Translate needle={labelPrefix + rowData["levelRulePluginId"]} />
          );
        },
      },
      {
        field: "maxLevelUpgrade",
        title: labelPrefix + "maxLevelUpgrade",
      },
      {
        field: "maxLevelDowngrade",
        title: labelPrefix + "maxLevelDowngrade",
      },
      {
        field: "levelGranularityType",
        title: labelPrefix + "levelGranularityType",
      },

      {
        field: "levelGranularityName",
        title: labelPrefix + "levelGranularityInfo",
      },
    ],
    granularityTypeList: [],
    granularityTypeInfoList: [],
    qualificationPeriodList: [],
    levelData: [],
    levelColumns: (divisorAmount) => {
      return [
        {
          field: "order",
          title: labelPrefix + "level." + "ranking",
        },
        { field: "name", title: labelPrefix + "level." + "name" },
        {
          field: "description",
          title: labelPrefix + "level." + "description",
        },
        {
          field: "pointToGetLevel",
          title: labelPrefix + "level." + "pointToGetLevel",
          render: (rowData) => {
            if (rowData["pointToGetLevel"]) {
              return (
                <Box display="flex">
                  <FormattedNumber
                    value={(rowData["pointToGetLevel"] / divisorAmount).toFixed(
                      2
                    )}
                  />
                </Box>
              );
            } else {
              return "-";
            }
          },
        },
        {
          field: "pointToMaintainLevel",
          title: labelPrefix + "level." + "pointToMaintainLevel",
          render: (rowData) => {
            if (rowData["pointToMaintainLevel"]) {
              return (
                <Box display="flex">
                  <FormattedNumber
                    value={(
                      rowData["pointToMaintainLevel"] / divisorAmount
                    ).toFixed(2)}
                  />
                </Box>
              );
            } else {
              return "-";
            }
          },
        },
        {
          field: "redeemablePointConversionFactor",
          title: labelPrefix + "level." + "redeemablePointConversionFactor",
          render: (rowData) => {
            if (rowData["redeemablePointConversionFactor"]) {
              return (
                <Box display="flex">
                  <FormattedNumber
                    value={(
                      rowData["redeemablePointConversionFactor"] / divisorAmount
                    ).toFixed(2)}
                  />
                </Box>
              );
            } else {
              return "-";
            }
          },
        },
      ];
    },
    levelLoading: false,
    prizeColumns: (divisorAmount) => {
      return [
        { field: "name", title: labelPrefix + "prize." + "name" },
        {
          field: "description",
          title: labelPrefix + "prize." + "description",
        },
        {
          field: "associatedLevel",
          title: labelPrefix + "prize." + "associatedLevel",
        },
        {
          field: "prizeType",
          title: labelPrefix + "prize." + "prizeType",
          render: (rowData) => {
            return (
              <Translate needle={"label.rewardType." + rowData["prizeType"]} />
            );
          },
        },
        {
          field: "neededAmount",
          title: labelPrefix + "prize." + "neededAmount",
          render: (rowData) => {
            if (rowData["neededAmount"]) {
              return (
                <Box display="flex">
                  <FormattedNumber
                    value={(rowData["neededAmount"] / divisorAmount).toFixed(2)}
                  />
                </Box>
              );
            } else {
              return "-";
            }
          },
        },
      ];
    },
    prizeLoading: false,
  },
};

const ProgramsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROGRAMS_REQUEST: {
      return {
        ...state,
        programs: {
          ...state.programs,
          programsLoading: true,
          programsData: null,
        },
      };
    }
    case PROGRAMS_SUCCESS: {
      return {
        ...state,
        programs: {
          ...state.programs,
          programsLoading: false,
          programsData: action.payload,
        },
      };
    }
    case PROGRAMS_FAILURE: {
      return {
        ...state,
        programs: {
          ...state.programs,
          programsLoading: false,
          programsData: null,
        },
      };
    }
    case PROGRAM_DETAIL_REQUEST: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          data: null,
          detailLoading: true,
        },
      };
    }
    case PROGRAM_DETAIL_SUCCESS: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          detailLoading: false,
          data: action.payload,
        },
      };
    }
    case PROGRAM_DETAIL_FAILURE: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          detailLoading: false,
          data: null,
        },
      };
    }
    case GRANULARITY_TYPE_SUCCESS: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          granularityTypeList: action.payload,
        },
      };
    }
    case GRANULARITY_TYPE_FAILURE: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          granularityTypeList: null,
        },
      };
    }
    case GRANULARITY_TYPE_INFO_SUCCESS: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          granularityTypeInfoList: action.payload,
        },
      };
    }
    case GRANULARITY_TYPE_INFO_FAILURE: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          granularityTypeInfoList: null,
        },
      };
    }
    case QUALIFICATION_PERIOD_SUCCESS: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          qualificationPeriodList: action.payload,
        },
      };
    }
    case QUALIFICATION_PERIOD_FAILURE: {
      return {
        ...state,
        programDetail: {
          ...state.programDetail,
          qualificationPeriodList: null,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default ProgramsReducer;
