import axios from "axios";

class SecuritySettingsService {
  async getSecuritySettings() {
    const response = await axios.get(`/gov/api/rest/v1/securitySettings`);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for Get User Security Settings");
  }

  async updateSecuritySettings(updateSecuritySettingsData) {
    const response = await axios.post(
      `/gov/api/rest/v1/securitySettings`,
      updateSecuritySettingsData
    );
    if (response && response.status !== 204) {
      throw Error("Invalid response for add limit");
    }
  }

  async getBAMSecuritySettings() {
    const response = await axios.get(`/gov/api/bam-rest/v1/global-settings`);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for Get User Security Settings");
  }
}

const securitySettingsService = new SecuritySettingsService();
export default securitySettingsService;
