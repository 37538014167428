import LoginSaga from "./store/authentication/login-saga";
import LogoutSaga from "./store/authentication/logout-saga";
import ExternalUrlSaga from "./store/external-url/external-url-saga";
import PwExpiredSaga from "./store/authentication/pw-expired-saga";
import SendMailSaga from "./store/authentication/send-mail-saga";
import PwRecoverySaga from "./store/authentication/pw-recovery-saga";
import { all, fork } from "@redux-saga/core/effects";
import SessionInfoSaga from "./store/authentication/sessioninfo-saga";
import TreeSideMenuSaga from "./store/tree-menu/side-menu-saga";
import LocaleSage from "./store/locale/locale-saga";
import LanguageSaga from "./store/locale/languages-saga";
import BookmarksSaga from "./store/bookmarks/bookmarks-saga";
import PageActionsSaga from "./store/page-actions/page-actions-saga";
import PlayerSaga from "./store/players/player-saga";
import WidgetSaga from "./store/widget/widget-saga";
import GamePlatformsSaga from "./store/game-platforms/game-platforms-saga";
import PlayerGroupsSaga from "./store/players/player-groups/player-groups-saga";
import ExpiringBonusSaga from "./store/players/bulk-rewards/expiring-bonus/expiring-bonus-saga";
import GameCodesSaga from "./store/game-codes/game-codes-saga";
import BulkOperationListSaga from "./store/players/bulk-rewards/bulk-operation-list/bulk-operation-list-saga";
import LoginHistorySaga from "./store/players/individual-player/login-history/login-history-saga";
import CustomerAccountUpdateSaga from "./store/players/individual-player/customer-account-update/customer-account-update-saga";
import ContractTypeListSaga from "./store/contract-type-list/contract-type-saga";
import IndividualPlayerGroupSaga from "./store/players/individual-player/player-groups/individual-player-groups-saga";
import PlayerDetailSaga from "./store/players/individual-player/player-detail/player-detail-saga";
import PlayerPaymentSaga from "./store/players/individual-player/payment/payment-saga";
import AccountDetailsSaga from "./store/players/individual-player/account-details/account-details-saga";
import BasicStepSaga from "./store/players/bulk-rewards/bulk-operation-add/basic-step/basic-step-saga";
import AccountTransactionsSaga from "./store/players/individual-player/account-transactions/account-transactions-saga";
import DirectNotificationsSaga from "./store/players/individual-player/direct-notifications/direct-notifications-saga";
import ChannelsSaga from "./store/channels/channels-saga";
import CommunicationHistorySaga from "./store/players/individual-player/communication-history/communication-history-saga";
import PlayerNotesSaga from "./store/players/individual-player/player-notes/player-notes-saga";
import FinancialLimitsSaga from "./store/players/individual-player/responsible-game/financial-limits/financial-limits-saga";
import PlayerLimitsSaga from "./store/players/individual-player/responsible-game/rgl-financial-limits/player-limits-saga";
import ErrorPageSaga from "./store/error-page/error-page-saga";
import PlayerFriendsSaga from "./store/players/individual-player/player-friends/player-friends-saga";
import LinkedAccountsSaga from "./store/players/individual-player/linked-accounts/linked-accounts-saga";
import CurrenciesSaga from "./store/currencies/currencies-saga";
import SecondStepSaga from "./store/players/bulk-rewards/bulk-operation-add/second-step/second-step-saga";
import KeyAccessManagementSaga from "./store/players/individual-player/key-access-management/key-access-management-saga";
import IndividualConsentsSaga from "./store/players/individual-player/individual-consents/individual-consents-saga";
import ExternalLinkedAccountsSaga from "./store/players/individual-player/external-linked-accounts/external-linked-accounts-saga";
import SelfExclusionSaga from "./store/players/individual-player/self-exclusion/self-exclusion-saga";
import NetworkLimitsSaga from "./store/players/individual-player/responsible-game/network-limits/network-limits-saga";
import GameWagerLimitsSaga from "./store/players/individual-player/responsible-game/game-wager-limits/game-wager-limits-saga";
import BonusAbuserHistorySaga from "./store/players/individual-player/rewards/bonus-abuser-history/bonus-abuser-history-saga";
import LoyaltyTransactionsSaga from "./store/players/individual-player/loyalty-transactions/loyalty-transactions-saga";
import UserPreferencesSaga from "./store/user-preferences/user-preferences-saga";
import BonusBagsSaga from "./store/players/individual-player/rewards/bonus-bags/bonus-bags-saga";
import LoyaltyDetailsSaga from "./store/players/individual-player/loyalty-details/loyalty-details-saga";
import LongRunningTaskSaga from "./store/players/individual-player/long-running-task/long-running-task-saga";
import TaskTipesSaga from "./store/task-types/task-types-saga";
import FreeSpinSaga from "./store/players/individual-player/free-spin/free-spin-saga";
import GamesOverviewSaga from "./store/players/individual-player/games-overview/games-overview-saga";
import TicketsSaga from "./store/players/individual-player/tickets/tickets-saga";
import GameTransactionsSaga from "./store/players/individual-player/game-transactions/game-transactions-saga";
import ReasonsSaga from "./store/clubCard-Reasons/reasons-saga";
import BarCodeSaga from "./store/clubCard-barCode/barCode_saga";
import PlatformNicknamesSaga from "./store/players/individual-player/platform-nicknames/platform-nicknames-saga";
import CampaignManagementSaga from "./store/rewards/campaigns/campaign-management/campaign-managemet-saga";
import LimitConfigurationSaga from "./store/rewards/loyalty/limit-configuration/limit-configuration-saga";
import LoyaltyTransactionTypesSaga from "./store/rewards/loyalty/loyalty-transaction-type-saga";
import ProgramsSaga from "./store/rewards/loyalty/programs/programs-saga";
import CommunicationsSaga from "./store/rewards/campaigns/communications/communications-saga";
import ClubCard from "./store/players/individual-player/club-card/club-card-saga";
import KYCStatusSaga from "./store/players/individual-player/kyc-status/kyc-status-saga";
import PromoCodeSaga from "./store/rewards/campaigns/promo-code/promo-code-saga";
import BlacklistManagement from "./store/rewards/campaigns/blacklist-management/blacklist-management-saga";
import TaxablePrizeSaga from "./store/taxable-prize/taxable-prize-saga";
import RafListSaga from "./store/rewards/raf/raf-list/raf-list-saga";
import PlayerFavoritesSaga from "./store/players/individual-player/player-Favorites/player-favorites-saga";
import NetworkBansAdminSaga from "./store/admin/player-configuration/network-bans/network-bans-saga";
import GameCategoriesSaga from "./store/game-categories/game-categories-saga";
import LocationsSaga from "./store/locations/locations-saga";
import ModalConfigSaga from "./store/modal-config/modal-config-saga";
import DeskSaga from "./store/desk/desk-saga";
import ContactVerificationSaga from "./store/players/individual-player/contact-verification/contact-verification-saga";
import PlayerContactsVerificationsSaga from "./store/admin/system/player-contacts-verification/player-contacts-verification-saga";
import ManageTemplateSaga from "./store/notifications/manage-template-saga";
import SensitiveVipManagementSaga from "./store/players/individual-player/sensitive-vip-manegement/sensitive-vip-manegement-saga";
import SensitiveLimitConfigurationSaga from "./store/admin/player-configuration/sensitive-limit-configuration/sensitive-limit-configuration-saga";
import TokenSaga from "./store/authentication/token-saga";
import SilentLoginSaga from "./store/silent-login/silent-login-saga";
import CommunicationPreferencesSaga from "./store/players/individual-player/communication-preferences/communication-preferences-saga";
import NotificationPreferencesSaga from "./store/players/individual-player/notification-preferences/notification-preferences-saga";
import PlayerPreferencesSaga from "./store/players/individual-player/player-preferences/player-preferences-saga";
import SessionLimitsSaga from "./store/players/individual-player/responsible-game/session-limits/session-limits-saga";
import ClosingAccountsSaga from "./store/closing-accounts/closing-accounts-saga";
import PlayerDocumentsSaga from "./store/players/individual-player/player-documents/player-documents-saga";
import PepSaga from "./store/players/pep/pep-saga";
import StatusHistorySaga from "./store/players/individual-player/status-history/status-history-saga";
import BlocklistSaga from "./store/players/individual-player/blocklist/blocklist-saga";
import LotteryBlocklistSaga from "./store/admin/player-configuration/lottery-blocklist/lottery-blocklist-saga";
import BulkRequestsSaga from "./store/admin/player-configuration/bulk-requests/bulk-requests-saga";
import UsersSaga from "./store/admin/security/users/users-saga";
import AdminLimitConfigurationSaga from "./store/admin/security/limitConfiguration/limit-configuration-saga";
import SecuritySettingsSaga from "./store/admin/security/securitySettings/security-settings-saga";
import LatestRegulatorStatusSaga from "./store/players/individual-player/latest-regulator-status/latest-regulator-status-saga";
import AssignRewardSaga from "./store/assign-reward/assign-reward-saga";
import OauthProviderSaga from "./store/authentication/oauth-provider-saga";
import ExportSaga from "./store/export/export-saga";
import PlayerACLserviceSaga from "./store/players/individual-player/player-ACLservice/player-ACLservice-saga";

const sagas = function* saga() {
  yield all([
    fork(LoginSaga),
    fork(LogoutSaga),
    fork(ExternalUrlSaga),
    fork(SessionInfoSaga),
    fork(PwExpiredSaga),
    fork(SendMailSaga),
    fork(PwRecoverySaga),
    fork(TreeSideMenuSaga),
    fork(LocaleSage),
    fork(LanguageSaga),
    fork(BookmarksSaga),
    fork(PageActionsSaga),
    fork(PlayerSaga),
    fork(WidgetSaga),
    fork(GamePlatformsSaga),
    fork(PlayerGroupsSaga),
    fork(ExpiringBonusSaga),
    fork(GameCodesSaga),
    fork(BulkOperationListSaga),
    fork(LoginHistorySaga),
    fork(CustomerAccountUpdateSaga),
    fork(ContractTypeListSaga),
    fork(IndividualPlayerGroupSaga),
    fork(PlayerDetailSaga),
    fork(PlayerPaymentSaga),
    fork(AccountDetailsSaga),
    fork(BasicStepSaga),
    fork(AccountTransactionsSaga),
    fork(DirectNotificationsSaga),
    fork(ChannelsSaga),
    fork(CommunicationHistorySaga),
    fork(PlayerNotesSaga),
    fork(FinancialLimitsSaga),
    fork(PlayerLimitsSaga),
    fork(ErrorPageSaga),
    fork(PlayerFriendsSaga),
    fork(LinkedAccountsSaga),
    fork(CurrenciesSaga),
    fork(SecondStepSaga),
    fork(KeyAccessManagementSaga),
    fork(IndividualConsentsSaga),
    fork(ExternalLinkedAccountsSaga),
    fork(SelfExclusionSaga),
    fork(NetworkLimitsSaga),
    fork(GameWagerLimitsSaga),
    fork(BonusAbuserHistorySaga),
    fork(LoyaltyTransactionsSaga),
    fork(UserPreferencesSaga),
    fork(BonusBagsSaga),
    fork(LoyaltyDetailsSaga),
    fork(LongRunningTaskSaga),
    fork(TaskTipesSaga),
    fork(FreeSpinSaga),
    fork(GamesOverviewSaga),
    fork(TicketsSaga),
    fork(GameTransactionsSaga),
    fork(ReasonsSaga),
    fork(BarCodeSaga),
    fork(PlatformNicknamesSaga),
    fork(CampaignManagementSaga),
    fork(LimitConfigurationSaga),
    fork(LoyaltyTransactionTypesSaga),
    fork(ProgramsSaga),
    fork(CommunicationsSaga),
    fork(ClubCard),
    fork(KYCStatusSaga),
    fork(PromoCodeSaga),
    fork(BlacklistManagement),
    fork(TaxablePrizeSaga),
    fork(RafListSaga),
    fork(PlayerFavoritesSaga),
    fork(NetworkBansAdminSaga),
    fork(GameCategoriesSaga),
    fork(LocationsSaga),
    fork(ContactVerificationSaga),
    fork(ModalConfigSaga),
    fork(DeskSaga),
    fork(PlayerContactsVerificationsSaga),
    fork(ManageTemplateSaga),
    fork(SensitiveVipManagementSaga),
    fork(SensitiveLimitConfigurationSaga),
    fork(TokenSaga),
    fork(SilentLoginSaga),
    fork(CommunicationPreferencesSaga),
    fork(NotificationPreferencesSaga),
    fork(PlayerPreferencesSaga),
    fork(SessionLimitsSaga),
    fork(ClosingAccountsSaga),
    fork(PlayerDocumentsSaga),
    fork(PepSaga),
    fork(StatusHistorySaga),
    fork(BlocklistSaga),
    fork(LotteryBlocklistSaga),
    fork(UsersSaga),
    fork(AdminLimitConfigurationSaga),
    fork(SecuritySettingsSaga),
    fork(LatestRegulatorStatusSaga),
    fork(AssignRewardSaga),
    fork(BulkRequestsSaga),
    fork(OauthProviderSaga),
    fork(ExportSaga),
    fork(PlayerACLserviceSaga),
  ]);
};

export default sagas;
