import axios from "axios";

class ProgramsService {
  async getPrograms(status, currencyType, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs?currency-type=${currencyType}`;
    if (status) {
      url += `&status=${status}`;
    }
    const response = await axios.get(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for get programs");
  }

  async createProgram(body, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs`;

    const response = await axios.post(url, body, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for create loyalty program");
  }

  async getProgramDetail(programId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/${programId}`;

    const response = await axios.get(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for get program detail");
  }

  async editProgram(body, programId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/${programId}`;
    if (body.status === "ACTIVE") {
      url += "/active";
    }

    if (body.levelGroups) {
      body.levelGroups.forEach((it) => {
        delete it.levelGranularityInfo;
      });
    }

    const response = await axios.put(url, body, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.status !== 204) {
      throw Error("Invalid response for edit loyalty program");
    }
  }

  async cancelProgram(body, programId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/${programId}/cancel`;

    if (body.levelGroups) {
      body.levelGroups.forEach((it) => {
        delete it.levelGranularityInfo;
      });
    }

    const response = await axios.put(url, body, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.status !== 204) {
      throw Error("Invalid response for cancel loyalty program");
    }
  }

  async activateProgram(body, programId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/${programId}/activate`;

    if (body.levelGroups) {
      body.levelGroups.forEach((it) => {
        delete it.levelGranularityInfo;
      });
    }

    const response = await axios.put(url, body, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.status !== 204) {
      throw Error("Invalid response for activate loyalty program");
    }
  }

  async suspendProgram(body, programId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/${programId}/suspend`;

    if (body.levelGroups) {
      body.levelGroups.forEach((it) => {
        delete it.levelGranularityInfo;
      });
    }

    const response = await axios.put(url, body, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.status !== 204) {
      throw Error("Invalid response for suspend loyalty program");
    }
  }

  async getGranularityType(programId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/granularity-type`;

    const response = await axios.get(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for get granularity type");
  }

  async getGranularityTypeInfo(granularityType, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/granularity?granularity-type=${granularityType}`;

    const response = await axios.get(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for get granularity type information");
  }

  async getQualificationPeriod(programId, brandId, partnerId) {
    let url = `/gov/api/rest/v1/programs/qulification-period`;

    const response = await axios.get(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for get qualification period");
  }

  async enableDisableLevel(
    programId,
    levelGroupName,
    levelName,
    enabled,
    brandId,
    partnerId
  ) {
    let url = `/gov/api/rest/v1/programs/${programId}/${levelGroupName}/${levelName}/level-status?enable=${enabled}`;

    const response = await axios.put(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for enable/disable level");
  }

  async enableDisablePrize(
    programId,
    levelGroupName,
    levelName,
    prizeName,
    enabled,
    brandId,
    partnerId
  ) {
    let url = `/gov/api/rest/v1/programs/${programId}/${levelGroupName}/${levelName}/${prizeName}/prize-status?enable=${enabled}`;

    const response = await axios.put(url, {
      headers: {
        "X-BRAND-ID": brandId,
        "X-PARTNER-ID": partnerId,
      },
    });

    if (response && response.data) {
      return response.data;
    }

    throw Error("Invalid response for enable/disable prize");
  }
}

const programsService = new ProgramsService();
export default programsService;
