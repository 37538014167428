import axios from "axios";

class PlayerACLservicesService {
  async updatePlayerACLservices(brandId, partnerId, body) {
    let url = `/gov/api/rest/v1/sms/update-service?brand=${brandId}&partner=${partnerId}`;

    const response = await axios.put(url, body);
    if (response && response.status !== 204) {
      throw Error("Error updating Player's ACL services in charge");
    }
  }
}

const playerACLservicesService = new PlayerACLservicesService();
export default playerACLservicesService;
