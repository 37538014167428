import { call, put } from "redux-saga/effects";
import {
  INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_SUCCESS,
  INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_FAILURE,
  INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_REQUEST,
  INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_REQUEST,
  INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_UNLOCK_REQUEST,
  INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_REQUEST,
  INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_SUCCESS,
  INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_FAILURE,
  INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_EDIT_MODAL,
  INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_SUCCESS,
  KEY_ACCESS_USERNAME,
  KEY_ACCESS_GAME_ACCOUNT,
} from "../../../constants";
import { takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../../snackbars/actions";
import keyAccessManagementService from "./key-access-management-service";

const getKeyAccessList = function* (action) {
  try {
    const data = yield call(
      keyAccessManagementService.loadKeyAccessList,
      action.contractId,
      action.brand,
      action.partner,
      action.registrationLevelId
    );
    yield put({
      type: INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("IndividualPlayerKeyAccessManagement", e);
    yield put({
      type: INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_FAILURE,
    });
  }
};

const resetKeyAccess = function* (action) {
  try {
    yield call(
      keyAccessManagementService.resetKeyAccess,
      action.contractId,
      action.userId,
      action.brand,
      action.identifier,
      action.keyType,
      action.partner
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.individualplayer.keyAccessManagement.reset_successfully",
        },
        options: {
          key: "ResetKeyAccess",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
  } catch (e) {
    console.error("IndividualPlayerKeyAccessManagement", e);
  }
};

const lockUnlockKeyAccess = function* (action) {
  try {
    const {
      contractId,
      brand,
      partner,
      toLock,
      unlockDate,
      keyTypeTranslated,
      identifier,
      registrationLevelId,
    } = action;
    const keyName =
      action.keyType === 1 ? KEY_ACCESS_USERNAME : KEY_ACCESS_GAME_ACCOUNT;
    let unlockDateLong = unlockDate ? unlockDate.valueOf() : null;
    yield call(
      keyAccessManagementService.lockUnlockKeyAccess,
      action.contractId,
      action.brand,
      action.identifier,
      action.keyType,
      keyName,
      action.partner,
      action.toLock,
      unlockDateLong
    );
    if (toLock) {
      yield put({
        type: INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_SUCCESS,
      });
    }
    yield put({
      type: INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_REQUEST,
      contractId,
      brand,
      partner,
      registrationLevelId,
    });
    const message = toLock
      ? "label.individualplayer.keyAccessManagement.lock_successfully"
      : "label.individualplayer.keyAccessManagement.unlock_successfully";
    const key = toLock ? "LockKeyAccess" : "UnlockKeyAccess";
    yield put(
      enqueueSnackbar({
        message: {
          needle: message,
          variables: [keyTypeTranslated, identifier],
        },
        options: {
          key: key,
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
  } catch (e) {
    console.error("IndividualPlayerKeyAccessManagement", e);
  }
};

const editKeyAccess = function* (action) {
  try {
    const keyName =
      action.keyType === 1 ? KEY_ACCESS_USERNAME : KEY_ACCESS_GAME_ACCOUNT;
    yield call(
      keyAccessManagementService.editKeyAccess,
      action.contractId,
      action.brand,
      action.identifier,
      action.keyType,
      keyName,
      action.partner,
      action.confirmPassword,
      action.newPassword
    );
    yield put({
      type: INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_SUCCESS,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle:
            "label.individualplayer.keyAccessManagement.edit_successfully",
        },
        options: {
          key: "EditKeyAccess",
          variant: "success",
          autoHideDuration: 10000,
        },
      })
    );
    yield put({
      type: INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_EDIT_MODAL,
    });
  } catch (e) {
    yield put({
      type: INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_FAILURE,
    });
  }
};

const KeyAccessManagementSaga = function* keyAccessManagementSaga() {
  yield takeLatest(INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_REQUEST, getKeyAccessList);
  yield takeLatest(INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_REQUEST, resetKeyAccess);
  yield takeLatest(
    INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_UNLOCK_REQUEST,
    lockUnlockKeyAccess
  );
  yield takeLatest(INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_REQUEST, editKeyAccess);
};

export default KeyAccessManagementSaga;
