import React, { useState } from "react";
import { connect } from "react-redux";

import {
  getKycStatus,
  getKycStatusHistory,
  getKycRequestDetail,
} from "../../../../../store/players/individual-player/kyc-status/actions";
import { useSession } from "../../../../../utilities/hooks/useSession";
import useGdprCheck from "../../../../../utilities/hooks/useGdprCheck";
import { usePlayerDetail } from "../../../../../utilities/hooks/usePlayerDetail";
import { useRouteMatch } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Edit from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import ListPanel from "../../../../../Layouts/Panel/ListPanel";
import { useTheme } from "@material-ui/styles";
import {
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import Translate from "../../../../../Layouts/Translator/Translate";
import {
  CachedRounded,
  Cancel,
  Check,
  RefreshRounded,
  Visibility,
} from "@material-ui/icons";
import { useTranslator } from "../../../../../utilities/hooks/useTranslator";
import Table from "../../../../../Layouts/Table/Table";
import { isMobileOnly } from "react-device-detect";
import Modal from "../../../../../Layouts/Modal/Modal";
import KYCRequestDetail from "./kycRequestDetail";
import KYCStatusAction from "./kycStatusAction";
import Action from "../../../../../Layouts/action/action";
import LimitedFeatureButton from "../../../../../Layouts/limited-feature-button/limited-feature-button";
import dataExporter from "../../../../../utilities/dataExporter";

const KYCStatus = ({
  data,
  loadKycStatus,
  dataList,
  loadKycStatusHistory,
  loading,
  fields,
  columns,
  filteredColumns,
  dataStatus,
  loadingHistory,
  loadKycRequestDetail,
  widget,
  dataRequest,
}) => {
  const match = useRouteMatch();
  const { brandSelected, partnerSelected, statusesForKYC } = useSession();
  const gdprCheck = useGdprCheck();
  const contractId = match.params.id;
  const t = useTranslator();
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [actionType, setActionType] = useState(false);
  const [actionColor, setActionColor] = useState(false);
  const [actionLabel, setActionLabel] = useState(false);

  const { registrationLevelId } = usePlayerDetail(
    contractId,
    brandSelected.id,
    partnerSelected.id,
    partnerSelected.pam
  );

  React.useEffect(() => {
    if (brandSelected && partnerSelected) {
      loadKycStatus(
        brandSelected,
        partnerSelected,
        contractId,
        registrationLevelId
      );
    }
  }, [
    brandSelected,
    partnerSelected,
    contractId,
    registrationLevelId,
    loadKycStatus,
  ]);

  React.useEffect(() => {
    if (brandSelected && partnerSelected) {
      loadKycStatusHistory(brandSelected, partnerSelected, contractId);
    }
  }, [brandSelected, partnerSelected, contractId, loadKycStatusHistory]);

  const handleOpenDetailModal = () => {
    setOpenDetailModal(!openDetailModal);
  };

  const handleOpenActionModal = () => {
    setOpenActionModal(!openActionModal);
  };

  const openRequestDetail = (detail) => {
    const { identityCheckRequestId } = detail;
    loadKycRequestDetail(
      brandSelected,
      partnerSelected,
      identityCheckRequestId
    );
    handleOpenDetailModal();
  };

  const openDownloadAction = (data, tableName) => {
    let exportColumns = ["TYPE", "CONTENT"];
    let exportData = [
      ["REQUEST", data.request],
      ["RESPONSE", data.response],
    ];
    dataExporter.exportPDF(
      exportColumns,
      exportData,
      t({
        needle: "label.player.kyc.detail.titlePDF",
        variables: [data.identityCheckRequestId],
      }),
      null,
      null,
      tableName
    );
  };

  const openStatusAction = (actionType, color, label) => {
    setActionType(actionType);
    setActionColor(color);
    setActionLabel(label);
    handleOpenActionModal();
  };

  var statusesForDivider = JSON.parse(statusesForKYC).statusesForDivider || [3, 4, 5, 6, 7];
  var statusesForRetry = JSON.parse(statusesForKYC).statusesForRetry || [7];
  var statusesForReset = JSON.parse(statusesForKYC).statusesForReset || [4, 6, 7];
  var statusesForReject = JSON.parse(statusesForKYC).statusesForReject || [3, 5, 6, 7];
  var statusesForApprove = JSON.parse(statusesForKYC).statusesForApprove || [3, 4, 5, 6, 7];

  const theme = useTheme();

  const isXl = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const getContent = () => {
    var maxH = null;
    if (isMd) {
      maxH = 500;
    } else if (isLg || isXl)  {
      maxH = 320;
    }
    return (
      <ListPanel
        maxHeight={widget ? null : maxH}
        key="0"
        data={data}
        loading={loading}
        editable={false}
        fields={gdprCheck(fields, filteredColumns)} 
      />
    );
  };

  const getActions = () => {
    return (
      <>
        {isMobileOnly ? (
          <Box display="flex" justifyContent="space-between" flex="1">
            <LimitedFeatureButton />
            {data != null && data.identityCheckRequestId != null && (
              <IconButton
                color={"primary"}
                onClick={() => {
                  openRequestDetail(data);
                }}
              >
                <Visibility />
              </IconButton>
            )}
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            flex="1"
            flexDirection={widget ? "column" : "row"}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              flex="1"
              mb={widget ? 1 : 0}
              mt={widget ? 1 : 0}
            >
              <Box>
                <Action
                  color={"primary"}
                  startIcon={<Edit />}
                  onClick={() => {
                    openStatusAction("EDIT_NOTE", "primary", "button.editNote");
                  }}
                  permission={"kyc_editNote"}
                  label={"button.editNote"}
                ></Action>
              </Box>
              {data != null && data.identityCheckRequestId != null && (
                <Box ml={1}>
                  <Button
                    color={"primary"}
                    startIcon={<Visibility />}
                    onClick={() => {
                      openRequestDetail(data);
                    }}
                  >
                    <Translate needle={"button.view"} />
                  </Button>
                </Box>
              )}
            </Box>
            {statusesForDivider.includes(dataStatus) && !widget ? (
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
            ) : null}
            <Box
              display="flex"
              justifyContent="flex-end"
              flex={widget ? 1 : 0}
              mb={widget ? 1 : 0}
            >
              {statusesForRetry.includes(dataStatus) ? (
                <Box ml={1}>
                  <Action
                    color={"primary"}
                    startIcon={<RefreshRounded />}
                    onClick={() => {
                      openStatusAction("RETRY", "primary", "button.retry");
                    }}
                    permission={"kyc_retry"}
                    label={"button.retry"}
                  ></Action>
                </Box>
              ) : null}
              {statusesForReset.includes(dataStatus) ? (
                <Box ml={1}>
                  <Action
                    color={"primary"}
                    startIcon={<CachedRounded />}
                    onClick={() => {
                      openStatusAction("RESET", "primary", "button.reset");
                    }}
                    permission={"kyc_reset"}
                    label={"button.reset"}
                  ></Action>
                </Box>
              ) : null}
              {statusesForReject.includes(dataStatus) ? (
                <Box ml={1}>
                  <Action
                    color={"failure"}
                    startIcon={<Cancel />}
                    onClick={() => {
                      openStatusAction("REJECT", "failure", "button.reject");
                    }}
                    permission={"kyc_reject"}
                    label={"button.reject"}
                  ></Action>
                </Box>
              ) : null}
              {statusesForApprove.includes(dataStatus) ? (
                <Box ml={1}>
                  <Action
                    color={"success"}
                    startIcon={<Check />}
                    onClick={() => {
                      openStatusAction("APPROVE", "success", "button.approve");
                    }}
                    permission={"kyc_approve"}
                    label={"button.approve"}
                  ></Action>
                </Box>
              ) : null}
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {!widget ? (
        <Box>
          <Card>
            <CardHeader title={t({ needle: "label.player.kyc.panelTitle" })} />
            <CardContent>{getContent()}</CardContent>
            <CardActions>
              {data != null && data !== "" ? getActions() : null}
            </CardActions>
          </Card>
          <Box mt={2}>
            <Table
              isLoading={loadingHistory}
              columns={gdprCheck(columns, filteredColumns)}
              data={dataList}
              type={"advanced"}
              pagination={true}
              exportButton={true}
              pageSize={10}
              page={0}
              title={"label.player.kyc.historyTable"}
              actions={[
                (rowData) => ({
                  icon: Visibility,
                  tooltip: "button.view",
                  hidden: rowData.identityCheckRequestId != null ? false : true,
                  onClick: (event, rowData) => {
                    openRequestDetail(rowData);
                  },
                }),
              ]}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          {getContent()}
          {getActions()}
        </Box>
      )}
      <Modal
        scroll={"body"}
        fullScreen={isMobileOnly}
        handleClose={isMobileOnly ? handleOpenDetailModal : null}
        open={openDetailModal}
        title={t({ needle: "label.player.kyc.detail.title" })}
        content={<KYCRequestDetail />}
        actions={[
          {
            color: "primary",
            label: "button.download",
            variant: "contained",
            handler: () =>
              openDownloadAction(
                dataRequest,
                t({ needle: "label.player.kyc.historyTable" })
              ),
          },
          {
            color: "primary",
            label: "button.close",
            variant: "contained",
            handler: handleOpenDetailModal,
          },
        ]}
      />
      <Modal
        scroll={"body"}
        fullScreen={isMobileOnly}
        handleClose={isMobileOnly ? handleOpenActionModal : null}
        open={openActionModal}
        title={t({ needle: `label.player.kyc.actionTitle.${actionType}` })}
        content={
          <KYCStatusAction
            handleOpenActionModal={handleOpenActionModal}
            actionType={actionType}
            actionColor={actionColor}
            actionLabel={actionLabel}
          />
        }
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    columns,
    filteredColumns,
    data,
    dataList,
    loading,
    fields,
    dataStatus,
    loadingHistory,
    dataRequest,
  } = state.KYCStatusReducer;
  return {
    columns,
    filteredColumns,
    data,
    dataList,
    loading,
    fields,
    dataStatus,
    loadingHistory,
    dataRequest,
  };
};

const mapDispatchToProps = {
  loadKycStatus: getKycStatus,
  loadKycStatusHistory: getKycStatusHistory,
  loadKycRequestDetail: getKycRequestDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(KYCStatus);
