import {
  INDIVIDUAL_PLAYER_LOGIN_HISTORY_SUCCESS,
  INDIVIDUAL_PLAYER_LOGIN_HISTORY_FAILURE,
  INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import Translate from "../../../../Layouts/Translator/Translate";

const initialState = {
  loginHistoryList: {
    loading: true,
    data: {
      page: 0,
      pageSize: 10
    },
    columns: [
      {
        field: "loginDateTime",
        title: "label.individualplayer.loginHistory.loginDate",
        default: true,
        defaultSort: "desc",
        render: (rowData) => {
          if (rowData["loginDateTime"]) {
            return <Moment format="L LT">{rowData["loginDateTime"]}</Moment>;
          }
        },
      },
      {
        field: "logoutDateTime",
        title: "label.individualplayer.loginHistory.logoutDate",
        render: (rowData) => {
          if (rowData["logoutDateTime"]) {
            return <Moment format="L LT">{rowData["logoutDateTime"]}</Moment>;
          }
        },
      },
      {
        field: "externalSystemDescrption",
        title: "label.individualplayer.loginHistory.system",
      },
      {
        field: "ipAddress",
        title: "label.individualplayer.loginHistory.ipAddress",
      },
      {
        field: "reason",
        title: "label.individualplayer.loginHistory.reason",
        render: (rowData) => {
          if (rowData["reason"]) {
            return (
              <Translate
                needle={
                  "label.individualplayer.loginHistory.reason." +
                  rowData["reason"]
                }
                label={rowData["reason"]}
              />
            );
          }
        },
      },
      {
        field: "deviceId",
        title: "label.individualplayer.loginHistory.deviceName",
        render: (rowData) => {
          if (rowData["deviceId"]) {
            return (
              <Translate
                needle={
                  "label.individualplayer.loginHistory.deviceId." +
                  rowData["deviceId"]
                }                
              />
            );
          }
        },
      },
    ],
  },
};
const LoginHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST: {
      return {
        ...state,
        loginHistoryList: {
          ...state.loginHistoryList,
          loading: true,
        },
      };
    }
    case INDIVIDUAL_PLAYER_LOGIN_HISTORY_SUCCESS: {
      return {
        ...state,
        loginHistoryList: {
          ...state.loginHistoryList,
          data: action.payload,
          loading: false,
        },
      };
    }
    case INDIVIDUAL_PLAYER_LOGIN_HISTORY_FAILURE: {
      return {
        ...state,
        loginHistoryList: {
          ...state.loginHistoryList,
          data: null,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export default LoginHistoryReducer;
