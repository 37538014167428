import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_USER_SECURITY_SETTINGS,
  GET_BAM_USER_SECURITY_SETTINGS,
  GET_USER_SECURITY_SETTINGS_FAILURE,
  GET_USER_SECURITY_SETTINGS_SUCCESS,
  SAVE_USER_SECURITY_SETTINGS_FAILURE,
  SAVE_USER_SECURITY_SETTINGS_REQUEST,
  SAVE_USER_SECURITY_SETTINGS_SUCCESS,
} from "../../../constants";
import securitySettingsService from "./security-settings-service";
import { enqueueSnackbar } from "../../../snackbars/actions";

const getSecuritySettings = function* (action) {
  try {
    const data = yield call(securitySettingsService.getSecuritySettings);
    yield put({
      type: GET_USER_SECURITY_SETTINGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("save security settings error", e);
    yield put({
      type: GET_USER_SECURITY_SETTINGS_FAILURE,
    });
  }
};

const getBAMSecuritySettings = function* (action) {
  try {
    const data = yield call(securitySettingsService.getBAMSecuritySettings);
    yield put({
      type: GET_USER_SECURITY_SETTINGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("save security settings error", e);
    yield put({
      type: GET_USER_SECURITY_SETTINGS_FAILURE,
    });
  }
};

const updateSecuritySettings = function* (action) {
  try {
    const data = yield call(
      securitySettingsService.updateSecuritySettings,
      action.updateSecuritySettingsData
    );
    yield put(
      enqueueSnackbar({
        message: {
          needle: "User security settings have been updated successfully",
        },
        options: {
          key: "approveSuccess",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );
    yield put({
      type: SAVE_USER_SECURITY_SETTINGS_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("save security settings error", e);
    yield put({
      type: SAVE_USER_SECURITY_SETTINGS_FAILURE,
    });
  }
};

const SecuritySettingsSaga = function* SecuritySettingsSaga() {
  yield takeLatest(GET_USER_SECURITY_SETTINGS, getSecuritySettings);
  yield takeLatest(SAVE_USER_SECURITY_SETTINGS_REQUEST, updateSecuritySettings);
  yield takeLatest(GET_BAM_USER_SECURITY_SETTINGS, getBAMSecuritySettings);
};

export default SecuritySettingsSaga;
