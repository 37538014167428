import {
  GET_BAM_USER_SECURITY_SETTINGS,
  GET_USER_SECURITY_SETTINGS,
  GET_USER_SECURITY_SETTINGS_FAILURE,
  GET_USER_SECURITY_SETTINGS_SUCCESS,
} from "../../../constants";

export const labelPrefix = "label.admin.security.securitySettings.";

const INITIAL_STATE = {
  securitySettings: {
    data: null,
    isLoading: false,
    lastRequest: null,
  },
};

const SecuritySettingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_SECURITY_SETTINGS:
      return {
        ...state,
        securitySettings: {
          ...state.securitySettings,
          isLoading: true,
          lastRequest: action,
        },
      };
    case GET_BAM_USER_SECURITY_SETTINGS:
      return {
        ...state,
        securitySettings: {
          ...state.securitySettings,
          isLoading: true,
          lastRequest: action,
        },
      };
    case GET_USER_SECURITY_SETTINGS_SUCCESS:
      return {
        ...state,
        securitySettings: {
          ...state.securitySettings,
          data: action.payload,
          isLoading: false,
        },
      };

    case GET_USER_SECURITY_SETTINGS_FAILURE:
      return {
        ...state,
        securitySettings: {
          ...state.securitySettings,
          data: null,
          isLoading: false,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default SecuritySettingsReducer;
