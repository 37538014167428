import {
  GET_BAM_USER_SECURITY_SETTINGS,
  GET_USER_SECURITY_SETTINGS,
  SAVE_USER_SECURITY_SETTINGS_REQUEST,
} from "../../../constants";

export const getSecuritySettings = () => {
  return {
    type: GET_USER_SECURITY_SETTINGS,
  };
};

export const getBAMSecuritySettings = () => {
  return {
    type: GET_BAM_USER_SECURITY_SETTINGS,
  };
};

export const updateSecuritySettings = (updateSecuritySettingsData, cb) => {
  return {
    type: SAVE_USER_SECURITY_SETTINGS_REQUEST,
    updateSecuritySettingsData,
    cb,
  };
};
