import {
  BUDGET_DETAILS_REQUEST,
  CAMPAIGN_DETAILS_FAILURE,
  CAMPAIGN_DETAILS_REQUEST,
  CAMPAIGN_DETAILS_SUCCESS,
  CAMPAIGN_REWARD_BUDGET_TYPES_SUCCESS,
  CAMPAIGN_REWARD_EVENT_TYPES_SUCCESS,
  CAMPAIGN_REWARD_EXCLUDED_PLAYERS_SUCCESS,
  CAMPAIGN_REWARD_TYPES_SUCCESS,
  CAMPAIGN_TAGS_SUCCESS,
  MANAGEMENT_LIST_FAILURE,
  MANAGEMENT_LIST_FILTERS_SUCCESS,
  MANAGEMENT_LIST_FILTERS_FAILURE,
  MANAGEMENT_LIST_REQUEST,
  MANAGEMENT_LIST_SUCCESS,
  PROMO_CODES_DETAILS_FAILURE,
  PROMO_CODES_DETAILS_REQUEST,
  PROMO_CODES_DETAILS_SUCCESS,
  REF_DETAILS_REQUEST,
  REWARDS_DETAILS_FAILURE,
  REWARDS_DETAILS_SUCCESS,
  STYLE_CURRENCY,
  CAMPAIGN_PARAMETER_TYPE_OPERATOR_SUCCESS,
  CAMPAIGN_PARAMETER_DEFINITIONS_SUCCESS,
  CAMPAIGN_ACCEPTANCE_TYPE_SUCCESS,
  CAMPAIGN_COMMUNICATIONS_SUCCESS,
  CAMPAIGN_FULL_DETAILS_SUCCESS,
  CAMPAIGN_FULL_DETAILS_REQUEST,
  CAMPAIGN_REWARD_RULE_SUCCESS,
  CAMPAIGN_REWARD_RULE_REQUEST,
  CAMPAIGN_CUSTOM_REWARD_SUCCESS,
} from "../../../constants";
import Moment from "react-moment";
import React from "react";
import * as moment from "moment";
import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import Translate from "../../../../Layouts/Translator/Translate";
import { FormattedNumber } from "react-intl";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import { Box, Button, IconButton } from "@material-ui/core";
import translateService from "../../../../utilities/translate";
import CurrencyBadge from "../../../../Layouts/badges/currency-badge";
import { Visibility } from "@material-ui/icons";
import { isMobileOnly } from "react-device-detect";
import Currency from "../../../../Layouts/Currency/Currency";
import { Link } from "react-router-dom";

export const labelPrefix = "label.rewards.campaigns.campaignManagement.";
export const rewardPrefix = labelPrefix + "reward.";

export const toStringSwitch = {
  0: "campaign-details",
  1: "budget-details",
  2: "promo-codes",
  3: "raf-details",
};
export const toNumSwitch = {
  "campaign-details": 0,
  "budget-details": 1,
  "promo-codes": 2,
  "raf-details": 3,
};
export const toStringBudgetDetails = {
  0: "total-rewarded-awards",
  1: "pending-rewards",
};
export const toNumBudgetDetails = {
  "total-rewarded-awards": 0,
  "pending-rewards": 1,
};
export const toStringPromoCodes = {
  0: "reserved-promo-codes",
  1: "confirmed-promo-codes",
  2: "voided-promo-codes",
};
export const toNumPromoCodes = {
  "reserved-promo-codes": 0,
  "confirmed-promo-codes": 1,
  "voided-promo-codes": 2,
};

export const getStatusMapping = (status) => {
  switch (status) {
    case "APPROVED": {
      return { id: "1", label: "approved" };
    }
    case "DRAFT": {
      return { id: "1", label: "draft" };
    }
    case "ACTIVE": {
      return { id: "2", label: "active" };
    }
    case "SIMULATE": {
      return { id: "2", label: "simulate" };
    }
    case "SUSPENDED": {
      return { id: "3", label: "suspended" };
    }
    case "OVER_BUDGET": {
      return { id: "5", label: "overBudget" };
    }
    case "EXPIRED": {
      return { id: "6", label: "expired" };
    }
    case "ERROR": {
      return { id: "4", label: "error" };
    }
    case "CANCELED": {
      return { id: "6", label: "cancelled" };
    }
    case "DISABLED": {
      return { id: "6", label: "disabled" };
    }
    case "CONFIRMED": {
      return { id: "2", label: "confirmed" };
    }
    case "VOIDED": {
      return { id: "6", label: "void" };
    }
    case "RESERVED": {
      return { id: "1", label: "reserved" };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};
export const getBudgetTypeMapping = (type) => {
  switch (type) {
    case "BY_AMOUNT": {
      return { id: "1", label: "byAmount" };
    }
    case "BY_NUMBER": {
      return { id: "1", label: "byNumber" };
    }
    case "DISABLED": {
      return { id: "6", label: "disabled" };
    }
    default: {
      return { id: "1", label: "unknown" };
    }
  }
};

const getFieldByType = (value, type, currency, locale, divisorAmount) => {
  moment.locale(locale);
  switch (type) {
    case "AMOUNT": {
      const options =
        currency !== "POINTS"
          ? {
              style: STYLE_CURRENCY,
              currency: currency,
              currencyDisplay: "symbol",
            }
          : {};
      return new Intl.NumberFormat(locale.replace("_", "-"), options).format(
        +value / divisorAmount
      );
    }

    case "DECIMAL2": {
      return parseFloat(value).toFixed(2);
    }

    case "DATE": {
      return moment(+value).format("L");
    }
    case "DATE_BOD":
    case "DATE_EOD":
    case "DATE_TIME": {
      return moment(+value).format("L LTS");
    }
    case "ANNIVERSARY": {
      let fullDate = moment(+value).format("L");
      return fullDate.slice(0, 5);
    }
    case "TIME": {
      return moment(+value).format("LTS");
    }
    case "NUMBER_RANGE_3":
    case "NUMBER_RANGE_5": {
      return value
        .substring(0, value.length - 1)
        .split(";")
        .map((it) => {
          let [amount, rest] = it.split("(");
          let [min, rest2] = rest.split("-");
          let [max] = rest2.split(")");
          return (
            "[" +
            new Intl.NumberFormat().format(min) +
            " - " +
            new Intl.NumberFormat().format(max) +
            "]" +
            new Intl.NumberFormat(amount, {
              style: STYLE_CURRENCY,
              currency: currency,
              currencyDisplay: "symbol",
            })
          );
        })
        .join("");
    }
    case "INTEGER": {
      return new Intl.NumberFormat().format(value);
    }
    case "SINGLE_SELECT": {
      const translations = {
        "fixed amount":
          "label.rewards.campaigns.campaignManagement.ruleName.fixedAmount",
        "total amount":
          "label.rewards.campaigns.campaignManagement.ruleName.totalAmount",
        "% (affecting amount)":
          "label.rewards.campaigns.campaignManagement.ruleName.affectingAmount",
        "multiplier (affecting amount)":
          "label.rewards.campaigns.campaignManagement.ruleName.multAffectingAmount",
        "fixed points":
          "label.rewards.campaigns.campaignManagement.ruleName.fixedPoints",
      };

      const key = value.trim().toLowerCase();
      return translations[key]
        ? translateService.translate({
            i18n: JSON.parse(sessionStorage.getItem("i18n")),
            needle: translations[key],
          })
        : value; // Caso di default
    }
    case "SWITCH": {
      return translateService.translate({
        i18n: JSON.parse(sessionStorage.getItem("i18n")),
        needle: "label." + value,
      });
    }
    default: {
      return value;
    }
  }
};

const initialState = {
  campaignEdit: {
    loading: true,
    data: {},
  },
  managementForm: {
    rewardTypes: [],
    excludedPlayers: [],
    rewardEventTypes: [],
    budgetTypes: [],
    tags: [],
    parameterOperatorMap: {},
    parameterDefinitions: [],
    acceptanceTypes: [],
    communications: [],
    rewardRules: [],
    customRewardDefinitions: [],
  },
  managementList: {
    idKey: "campaignId",
    filtersLoading: true,
    tableLoading: true,
    filters: [],
    filtersKey: "",
    data: [],
    columns: [
      {
        field: "campaignId",
        title: labelPrefix + "campaignId",
      },
      {
        field: "name",
        title: labelPrefix + "name",
        default: true,
      },
      {
        field: "creationDate",
        title: labelPrefix + "createDate",
        render: (rowData) => {
          if (rowData["creationDate"]) {
            return <Moment format="L LT">{rowData["creationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["creationDate"]) {
            return moment(rowData["creationDate"]).format("L LT");
          }
        },
      },
      {
        field: "activationDate",
        title: labelPrefix + "activesOn",
        render: (rowData) => {
          if (rowData["activationDate"]) {
            return <Moment format="L LT">{rowData["activationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["activationDate"]) {
            return moment(rowData["activationDate"]).format("L LT");
          }
        },
      },
      {
        field: "expirationDate",
        title: labelPrefix + "expiresOn",
        render: (rowData) => {
          if (rowData["expirationDate"]) {
            return <Moment format="L LTS">{rowData["expirationDate"]}</Moment>;
          }
        },
        getExportValueFn: (rowData) => {
          if (rowData["expirationDate"]) {
            return moment(rowData["expirationDate"]).format("L LT");
          }
        },
      },
      {
        field: "status",
        title: labelPrefix + "status",
        default: true,
        nowrap: false,
        render: (rowData) => {
          if (rowData["status"]) {
            const statusMapping = getStatusMapping(rowData["status"]);
            return (
              <BadgeStatus
                id={statusMapping.id}
                label={statusMapping.label}
                justify={"center"}
              />
            );
          }
        },
      },
      {
        field: "actions",
        title: labelPrefix + "action",
        render: (rowData) => {
          if (rowData["actions"] && rowData["actions"].length > 0) {
            return rowData["actions"]
              .map((it) =>
                translateService.translateIt("label.eventType." + it)
              )
              .join(", ");
          }
        },
      },
      {
        field: "rewardType",
        title: labelPrefix + "rewardType",
        render: (rowData) => {
          if (rowData["rewardType"]) {
            const rewardType = "label.rewardType." + rowData["rewardType"];
            return <Translate needle={rewardType} />;
          }
        },
      },
      {
        field: "referFriend",
        title: labelPrefix + "referAfriend",
        nowrap: false,
        render: (rowData) => {
          return (
            <Box display={"flex"} justifyContent={"center"}>
              <YesNoBadge value={rowData["referFriend"]} />
            </Box>
          );
        },
        getExportValueFn: (rowData) => {
          return `${rowData["referFriend"]}`;
        },
      },
      {
        field: "priority",
        title: labelPrefix + "priority",
        render: (rowData) => {
          if (rowData["priority"]) {
            return <FormattedNumber value={rowData["priority"]} />;
          }
        },
      },
      {
        field: "cumulateWithSameEvent",
        title: labelPrefix + "cumulateWithSameEvent",
        nowrap: false,
        render: (rowData) => {
          return (
            <Box display={"flex"} justifyContent={"center"}>
              <YesNoBadge value={rowData["cumulateWithSameEvent"]} />
            </Box>
          );
        },
      },
    ],
  },
  campaignDetails: {
    loading: true,
    data: [],
    panels: [
      {
        name: "campaignDetails",
        title: labelPrefix + "campaignDetails.tabTitle",
        column: 1,
        isCollapsed: false,
        fields: [
          {
            field: "campaignId",
            title: labelPrefix + "campaignId",
          },
          {
            field: "name",
            title: labelPrefix + "name",
          },
          {
            field: "description",
            title: labelPrefix + "campaignDetails.description",
          },
          {
            field: "status",
            title: labelPrefix + "status",
            render: (rowData) => {
              if (rowData["status"]) {
                const statusMapping = getStatusMapping(rowData["status"]);
                return (
                  <BadgeStatus
                    id={statusMapping.id}
                    label={statusMapping.label}
                  />
                );
              }
            },
          },
          {
            field: "rewardTypeId",
            title: labelPrefix + "rewardType",
            render: (rowData) => {
              if (rowData["rewardTypeId"]) {
                const rewardType =
                  "label.rewardType." + rowData["rewardTypeId"];
                return <Translate needle={rewardType} />;
              }
            },
          },
          {
            field: "eventTypeId",
            title: labelPrefix + "campaignDetails.action",
            render: (rowData) => {
              if (rowData["eventTypeId"] && rowData["eventTypeId"].length > 0) {
                return rowData["eventTypeId"]
                  .map((it) =>
                    translateService.translateIt("label.eventType." + it)
                  )
                  .join(", ");
              }
            },
          },
          {
            field: "excludedPlayerWardedByCampaignDetail",
            title:
              labelPrefix +
              "campaignDetails.excludedPlayerWardedByCampaignDetail",
            render: (rowData) => {
              if (
                rowData["excludedPlayerWardedByCampaignDetail"] &&
                rowData["excludedPlayerWardedByCampaignDetail"].length > 0
              ) {
                return rowData["excludedPlayerWardedByCampaignDetail"]
                  .map((it) => it.name)
                  .join(", ");
              }
            },
          },
          {
            field: "welcome",
            title: labelPrefix + "campaignDetails.welcome",
            render: (rowData) => {
              return <YesNoBadge value={rowData["welcome"]} />;
            },
          },
          {
            field: "cumulateWithSameEvent",
            title: labelPrefix + "campaignDetails.cumulateWithSameEvent",
            render: (rowData) => {
              return <YesNoBadge value={rowData["cumulateWithSameEvent"]} />;
            },
          },
          {
            field: "priority",
            title: labelPrefix + "priority",
            render: (rowData) => {
              if (rowData["priority"]) {
                return <FormattedNumber value={rowData["priority"]} />;
              }
            },
          },
          {
            field: "simulate",
            title: labelPrefix + "campaignDetails.simulate",
            render: (rowData) => {
              return <YesNoBadge value={rowData["simulate"]} />;
            },
          },
          {
            field: "checkConsent",
            title: labelPrefix + "campaignDetails.checkConsent",
            render: (rowData) => {
              return <YesNoBadge value={rowData["checkConsent"]} />;
            },
          },
          {
            field: "visibleToPlayer",
            title: labelPrefix + "campaignDetails.visibleToPlayer",
            render: (rowData) => {
              return <YesNoBadge value={rowData["visibleToPlayer"]} />;
            },
          },
          {
            field: "activationDate",
            title: labelPrefix + "activesOn",
            render: (rowData) => {
              if (rowData["activationDate"]) {
                return (
                  <Moment format="L LT">{rowData["activationDate"]}</Moment>
                );
              }
            },
          },
          {
            field: "expirationDate",
            title: labelPrefix + "expiresOn",
            render: (rowData) => {
              if (rowData["expirationDate"]) {
                return (
                  <Moment format="L LT">{rowData["expirationDate"]}</Moment>
                );
              }
            },
          },
          {
            field: "planningDate",
            title: labelPrefix + "campaignDetails.planningDate",
            render: (rowData) => {
              if (rowData["planningDate"]) {
                return <Moment format="L LT">{rowData["planningDate"]}</Moment>;
              }
            },
          },
          {
            field: "lastUpdateDate",
            title: labelPrefix + "campaignDetails.lastUpdateDate",
            render: (rowData) => {
              if (rowData["lastUpdateDate"]) {
                return (
                  <Moment format="L LT">{rowData["lastUpdateDate"]}</Moment>
                );
              }
            },
          },
          {
            field: "lastSuspensionDate",
            title: labelPrefix + "campaignDetails.lastSuspensionDate",
            render: (rowData) => {
              if (rowData["lastSuspensionDate"]) {
                return (
                  <Moment format="L LT">{rowData["lastSuspensionDate"]}</Moment>
                );
              }
            },
          },
          {
            field: "tags",
            title: labelPrefix + "campaignDetails.tags",
            render: (rowData) => {
              if (rowData["tags"] && rowData["tags"].length > 0) {
                return rowData["tags"].join(", ");
              }
            },
          },
          {
            field: "bamOperatorApprover",
            title: labelPrefix + "campaignDetails.bamOperatorApprover",
          },
          {
            field: "bamOperatorCreator",
            title: labelPrefix + "campaignDetails.bamOperatorCreator",
          },
          {
            field: "lastBamOperatorUpdate",
            title: labelPrefix + "campaignDetails.lastBamOperatorUpdate",
          },
        ],
      },
      {
        name: "budgetDetails",
        column: 1,
        link: "budget-details",
        title: labelPrefix + "campaignDetails.budgetDetails",
        fields: (showButtons) => {
          return [
            {
              field: "currency",
              title: labelPrefix + "campaignDetails.currency",
              render: (rowData) => {
                if (rowData["currency"]) {
                  return <CurrencyBadge currency={rowData["currency"]} />;
                }
              },
            },
            {
              field: "totalNumberRewarded",
              title: labelPrefix + "campaignDetails.totalNumberRewarded",
              render: (rowData) => {
                const buttonProps = {
                  style: { marginLeft: "auto" },
                  component: Link,
                  to: "budget-details/total-rewarded-awards",
                  startIcon: <Visibility />,
                  color: "primary",
                };
                return (
                  <>
                    <FormattedNumber value={rowData["totalNumberRewarded"]} />

                    {showButtons &&
                    !isMobileOnly &&
                    rowData["totalNumberRewarded"] > 0 ? (
                      <Button {...buttonProps}>
                        <Translate needle={"button.view"} />
                      </Button>
                    ) : (
                      showButtons &&
                      rowData["totalNumberRewarded"] > 0 && (
                        <IconButton {...buttonProps}>
                          {buttonProps.startIcon}
                        </IconButton>
                      )
                    )}
                  </>
                );
              },
            },
            {
              field: "totalAmountRewarded",
              title: labelPrefix + "campaignDetails.totalAmountRewarded",
              render: (rowData) => {
                if (rowData["totalAmountRewarded"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      currencyPrecision={rowData["currencyPrecision"]}
                      amount={rowData["totalAmountRewarded"]}
                    />
                  );
                }
              },
            },
            {
              field: "pendingRewards",
              title: labelPrefix + "campaignDetails.pendingRewards",
              render: (rowData) => {
                const buttonProps = {
                  style: { marginLeft: "auto" },
                  component: Link,
                  to: "budget-details/pending-rewards",
                  startIcon: <Visibility />,
                  color: "primary",
                };
                return (
                  <>
                    <FormattedNumber value={rowData["pendingRewards"]} />
                    {showButtons &&
                    !isMobileOnly &&
                    rowData["pendingRewards"] > 0 ? (
                      <Button {...buttonProps}>
                        <Translate needle={"button.view"} />
                      </Button>
                    ) : (
                      showButtons &&
                      rowData["pendingRewards"] > 0 && (
                        <IconButton {...buttonProps}>
                          {buttonProps.startIcon}
                        </IconButton>
                      )
                    )}
                  </>
                );
              },
            },
            {
              field: "pendingRewardAmount",
              title: labelPrefix + "campaignDetails.pendingRewardAmount",
              render: (rowData) => {
                if (rowData["pendingRewardAmount"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      currencyPrecision={rowData["currencyPrecision"]}
                      amount={rowData["pendingRewardAmount"]}
                    />
                  );
                }
              },
            },
            {
              field: "budget.playerBudgetType",
              title: labelPrefix + "campaignDetails.playerBudgetType",
              render: (rowData) => {
                if (rowData["budget"]["playerBudgetType"]) {
                  const typeMapping = getBudgetTypeMapping(
                    rowData["budget"]["playerBudgetType"]
                  );
                  return (
                    <BadgeStatus
                      id={typeMapping.id}
                      label={typeMapping.label}
                    />
                  );
                }
              },
            },
            {
              field: "budget.playerRewardNumber",
              title: labelPrefix + "campaignDetails.playerRewardNumber",
              render: (rowData) => {
                if (rowData["budget"]["playerRewardNumber"]) {
                  return (
                    <FormattedNumber
                      value={rowData["budget"]["playerRewardNumber"]}
                    />
                  );
                }
              },
            },
            {
              field: "budget.playerRewardAmount",
              title: labelPrefix + "campaignDetails.playerRewardAmount",
              render: (rowData) => {
                if (rowData["budget"]["playerRewardAmount"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["budget"]["playerRewardAmount"]}
                      currencyPrecision={rowData["currencyPrecision"]}
                    />
                  );
                }
              },
            },
            {
              field: "budget.campaignBudgetType",
              title: labelPrefix + "campaignDetails.campaignBudgetType",
              render: (rowData) => {
                if (rowData["budget"]["campaignBudgetType"]) {
                  const typeMapping = getBudgetTypeMapping(
                    rowData["budget"]["campaignBudgetType"]
                  );
                  return (
                    <BadgeStatus
                      id={typeMapping.id}
                      label={typeMapping.label}
                    />
                  );
                }
              },
            },
            {
              field: "budget.campaignRewardNumber",
              title: labelPrefix + "campaignDetails.campaignRewardNumber",
              render: (rowData) => {
                if (rowData["budget"]["campaignRewardNumber"]) {
                  return (
                    <FormattedNumber
                      value={rowData["budget"]["campaignRewardNumber"]}
                    />
                  );
                }
              },
            },
            {
              field: "budget.campaignRewardAmount",
              title: labelPrefix + "campaignDetails.campaignRewardAmount",
              render: (rowData) => {
                if (rowData["budget"]["campaignRewardAmount"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["budget"]["campaignRewardAmount"]}
                      currencyPrecision={rowData["currencyPrecision"]}
                    />
                  );
                }
              },
            },
            {
              field: "budget.warningPercentage",
              title: labelPrefix + "campaignDetails.warningPercentage",
              render: (rowData) => {
                if (rowData["budget"]["warningPercentage"]) {
                  return (
                    <FormattedNumber
                      value={rowData["budget"]["warningPercentage"]}
                    />
                  );
                }
              },
            },
            {
              field: "budget.warningDestination",
              title: labelPrefix + "campaignDetails.warningDestination",
            },
          ];
        },
      },
      {
        name: "promoCodes",
        column: 1,
        link: "promo-codes",
        title: labelPrefix + "campaignDetails.promoCodes",
        fields: (showButtons) => {
          return [
            {
              field: "promoCodes.totalPromoCode",
              title: labelPrefix + "campaignDetails.totalPromoCode",
              render: (rowData) => {
                if (rowData["promoCodes"]["totalPromoCode"]) {
                  return (
                    <FormattedNumber
                      value={rowData["promoCodes"]["totalPromoCode"]}
                    />
                  );
                }
              },
            },
            {
              field: "promoCodes.reservedPromoCode",
              title: labelPrefix + "campaignDetails.reservedPromoCode",
              render: (rowData) => {
                const buttonProps = {
                  style: { marginLeft: "auto" },
                  component: Link,
                  to: "promo-codes/reserved-promo-codes",
                  startIcon: <Visibility />,
                  color: "primary",
                };
                return (
                  <>
                    <FormattedNumber
                      value={rowData["promoCodes"]["reservedPromoCode"]}
                    />
                    {showButtons &&
                    !isMobileOnly &&
                    rowData["promoCodes"]["reservedPromoCode"] > 0 ? (
                      <Button {...buttonProps}>
                        <Translate needle={"button.view"} />
                      </Button>
                    ) : (
                      showButtons &&
                      rowData["promoCodes"]["reservedPromoCode"] > 0 && (
                        <IconButton {...buttonProps}>
                          {buttonProps.startIcon}
                        </IconButton>
                      )
                    )}
                  </>
                );
              },
            },
            {
              field: "promoCodes.confirmedPromCode",
              title: labelPrefix + "campaignDetails.confirmedPromCode",
              render: (rowData) => {
                const buttonProps = {
                  style: { marginLeft: "auto" },
                  component: Link,
                  to: "promo-codes/confirmed-promo-codes",
                  startIcon: <Visibility />,
                  color: "primary",
                };
                return (
                  <>
                    <FormattedNumber
                      value={rowData["promoCodes"]["confirmedPromCode"]}
                    />
                    {showButtons &&
                    !isMobileOnly &&
                    rowData["promoCodes"]["confirmedPromCode"] > 0 ? (
                      <Button {...buttonProps}>
                        <Translate needle={"button.view"} />
                      </Button>
                    ) : (
                      showButtons &&
                      rowData["promoCodes"]["confirmedPromCode"] > 0 && (
                        <IconButton {...buttonProps}>
                          {buttonProps.startIcon}
                        </IconButton>
                      )
                    )}
                  </>
                );
              },
            },
            {
              field: "promoCodes.voidedPromoCode",
              title: labelPrefix + "campaignDetails.voidedPromoCode",
              render: (rowData) => {
                const buttonProps = {
                  style: { marginLeft: "auto" },
                  component: Link,
                  to: "promo-codes/voided-promo-codes",
                  startIcon: <Visibility />,
                  color: "primary",
                };
                return (
                  <>
                    <FormattedNumber
                      value={rowData["promoCodes"]["voidedPromoCode"]}
                    />
                    {showButtons &&
                    !isMobileOnly &&
                    rowData["promoCodes"]["voidedPromoCode"] > 0 ? (
                      <Button {...buttonProps}>
                        <Translate needle={"button.view"} />
                      </Button>
                    ) : (
                      showButtons &&
                      rowData["promoCodes"]["voidedPromoCode"] > 0 && (
                        <IconButton {...buttonProps}>
                          {buttonProps.startIcon}
                        </IconButton>
                      )
                    )}
                  </>
                );
              },
            },
          ];
        },
      },
      {
        name: "playerGroups",
        column: 1,
        title: labelPrefix + "campaignDetails.playerGroups",
        fields: [
          {
            field: "playerGroups",
            title: labelPrefix + "campaignDetails.playerGroups",
            render: (rowData) => {
              if (rowData["playerGroups"].length > 0) {
                return rowData["playerGroups"].join(", ");
              }
            },
          },
          {
            field: "acceptanceType",
            title: labelPrefix + "campaignDetails.acceptanceType",
            render: (rowData) => {
              return (
                <Translate
                  needle={
                    labelPrefix + "acceptanceType." + rowData["acceptanceType"]
                  }
                />
              );
            },
          },
          {
            field: "activationCode",
            title: labelPrefix + "campaignDetails.activationCode",
          },
        ],
      },
      {
        name: "communications",
        column: 2,
        title: labelPrefix + "campaignDetails.communications",
        fields: [
          {
            field: "communications",
            title: labelPrefix + "campaignDetails.communicationName",
            render: (rowData) => {
              if (rowData["communications"].length > 0) {
                return rowData["communications"].join(", ");
              }
            },
          },
        ],
      },
      {
        name: "filtersParameters",
        column: 2,
        title: labelPrefix + "reward.filtersParameters",
        labelPrefix: labelPrefix + "parameterName.",
      },
      {
        name: "refDetails",
        title: labelPrefix + "campaignDetails.refDetails",
        link: "raf-details",
        column: 2,
        fields: (showButtons) => {
          return [
            {
              field: "currency",
              title: labelPrefix + "campaignDetails.currency",
              render: (rowData) => {
                if (rowData["currency"]) {
                  return <CurrencyBadge currency={rowData["currency"]} />;
                }
              },
            },
            {
              field: "totalReferrerNumberRewarded",
              title: labelPrefix + "campaignDetails.totalNumberRewarded",
              render: (rowData) => {
                const buttonProps = {
                  style: { marginLeft: "auto" },
                  component: Link,
                  to: "raf-details/total-rewarded-awards",
                  startIcon: <Visibility />,
                  color: "primary",
                };
                return (
                  <>
                    <FormattedNumber
                      value={rowData["totalReferrerNumberRewarded"]}
                    />
                    {showButtons &&
                    !isMobileOnly &&
                    rowData["totalReferrerNumberRewarded"] > 0 ? (
                      <Button {...buttonProps}>
                        <Translate needle={"button.view"} />
                      </Button>
                    ) : (
                      showButtons &&
                      rowData["totalReferrerNumberRewarded"] > 0 && (
                        <IconButton {...buttonProps}>
                          {buttonProps.startIcon}
                        </IconButton>
                      )
                    )}
                  </>
                );
              },
            },
            {
              field: "totalReferrerAmountRewarded",
              title: labelPrefix + "campaignDetails.totalAmountRewarded",
              render: (rowData) => {
                if (rowData["totalReferrerAmountRewarded"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["totalReferrerAmountRewarded"]}
                    />
                  );
                }
              },
            },
            {
              field: "pendingReferrerRewards",
              title: labelPrefix + "campaignDetails.pendingRewards",
              render: (rowData) => {
                const buttonProps = {
                  style: { marginLeft: "auto" },
                  component: Link,
                  to: "raf-details/pending-rewards",
                  startIcon: <Visibility />,
                  color: "primary",
                };
                return (
                  <>
                    <FormattedNumber
                      value={rowData["pendingReferrerRewards"]}
                    />
                    {showButtons &&
                    !isMobileOnly &&
                    rowData["pendingReferrerRewards"] > 0 ? (
                      <Button {...buttonProps}>
                        <Translate needle={"button.view"} />
                      </Button>
                    ) : (
                      showButtons &&
                      rowData["pendingReferrerRewards"] > 0 && (
                        <IconButton {...buttonProps}>
                          {buttonProps.startIcon}
                        </IconButton>
                      )
                    )}
                  </>
                );
              },
            },
            {
              field: "pendingReferrerRewardAmount",
              title: labelPrefix + "campaignDetails.pendingRewardAmount",
              render: (rowData) => {
                if (rowData["pendingReferrerRewardAmount"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["pendingReferrerRewardAmount"]}
                    />
                  );
                }
              },
            },
            {
              field: "referrerRewardTypeId",
              title: labelPrefix + "rewardType",
              render: (rowData) => {
                if (rowData["referrerRewardTypeId"]) {
                  const rewardType =
                    "label.rewardType." + rowData["referrerRewardTypeId"];
                  return <Translate needle={rewardType} />;
                }
              },
            },
            {
              field: "referFriendDetail.endInviteDate",
              title: labelPrefix + "campaignDetails.endInviteDate",
              render: (rowData) => {
                if (rowData["referFriendDetail"]["endInviteDate"]) {
                  return (
                    <Moment format="L LT">
                      {rowData["referFriendDetail"]["endInviteDate"]}
                    </Moment>
                  );
                }
              },
            },
            {
              field: "referFriendDetail.postRegistrationDays",
              title: labelPrefix + "campaignDetails.postRegistrationDays",
              render: (rowData) => {
                if (rowData["referFriendDetail"]["postRegistrationDays"]) {
                  return (
                    <FormattedNumber
                      value={
                        rowData["referFriendDetail"]["postRegistrationDays"]
                      }
                    />
                  );
                }
              },
            },
            {
              field: "referFriendDetail.maxFriend",
              title: labelPrefix + "campaignDetails.maxFriend",
              render: (rowData) => {
                if (rowData["referFriendDetail"]["maxFriend"]) {
                  return (
                    <FormattedNumber
                      value={rowData["referFriendDetail"]["maxFriend"]}
                    />
                  );
                }
              },
            },
            {
              field: "referFriendDetail.extraBonusAmount",
              title: labelPrefix + "campaignDetails.extraBonusAmount",
              render: (rowData) => {
                if (rowData["referFriendDetail"]["extraBonusAmount"]) {
                  return (
                    <Currency
                      currency={rowData["currency"]}
                      amount={rowData["referFriendDetail"]["extraBonusAmount"]}
                    />
                  );
                }
              },
            },
            {
              field: "referFriendDetail.extraBonusForEachFriend",
              title: labelPrefix + "campaignDetails.extraBonusForEachFriend",
              render: (rowData) => {
                if (rowData["referFriendDetail"]["extraBonusForEachFriend"]) {
                  return (
                    <FormattedNumber
                      value={
                        rowData["referFriendDetail"]["extraBonusForEachFriend"]
                      }
                    />
                  );
                }
              },
            },
            {
              field: "referFriendDetail.invitationDeliveryChannel",
              title: labelPrefix + "campaignDetails.invitationDeliveryChannel",
            },
            {
              field: "referFriendDetail.invitationTemplate",
              title: labelPrefix + "campaignDetails.invitationTemplate",
            },
            {
              field: "referFriendDetail.referrerRewardDeliveryChannel",
              title:
                labelPrefix + "campaignDetails.referrerRewardDeliveryChannel",
            },
            {
              field: "referFriendDetail.referrerRewardTemplate",
              title: labelPrefix + "campaignDetails.referrerRewardTemplate",
            },
          ];
        },
      },
      {
        name: "referrerReward",
        column: 2,
        title: labelPrefix + "reward.referrerReward",
      },
      {
        name: "reward",
        column: 2,
        title: labelPrefix + "reward.panelTitle",
      },
      {
        name: "rewardRule",
        column: 2,
        title: labelPrefix + "reward.rewardRule",
        fields: [
          {
            field: "ruleTypeId",
            title: labelPrefix + "campaignDetails.ruleName",
            render: (rowData) => {
              if (rowData["ruleTypeId"]) {
                return (
                  <Translate
                    needle={labelPrefix + "ruleName." + rowData["ruleTypeId"]}
                  />
                );
              }
            },
          },
        ],
      },
      {
        name: "rewardRuleParameters",
        column: 2,
        title: labelPrefix + "reward.rewardRuleParameters",
        labelPrefix: labelPrefix + "ruleName.",
      },
    ],

    createRewardPanel: (rewardType, campaignCurrency) => {
      const bonusBag = [
        { field: "name" },
        { field: "description" },
        { field: "constantPriority" },
        {
          field: "bonusTypeId.name",
          title: rewardPrefix + "bonusBagType",
          render: (rowData) => {
            if (rowData["bonusTypeId"]) {
              return (
                <Translate
                  needle={`label.player.bonusBags.list.bonusTypeId.${rowData["bonusTypeId"].value}`}
                />
              );
            }
          },
        },
        {
          field: "bbmQrType.name",
          title: rewardPrefix + "qrType",
          render: (rowData) => {
            if (rowData["bbmQrType"]) {
              return (
                <Translate
                  needle={`label.player.bonusBags.list.bbmQrType.${rowData["bbmQrType"].value}`}
                />
              );
            }
          },
        },
        {
          field: "usableGamePlatforms",
          render: (rowData) => {
            if (
              rowData["usableGamePlatforms"] &&
              rowData["usableGamePlatforms"].length > 0
            ) {
              return rowData["usableGamePlatforms"]
                .map((it) => it.name)
                .join(", ");
            }
          },
        },
        {
          field: "usableGameCodes",
          render: (rowData) => {
            if (
              rowData["usableGameCodes"] &&
              rowData["usableGameCodes"].length > 0
            ) {
              return rowData["usableGameCodes"].map((it) => it.name).join(", ");
            }
          },
        },
        {
          field: "usableGameFamilies",
          render: (rowData) => {
            if (
              rowData["usableGameFamilies"] &&
              rowData["usableGameFamilies"].length > 0
            ) {
              return rowData["usableGameFamilies"]
                .map((it) => it.name)
                .join(", ");
            }
          },
        },
        {
          field: "usableDevices",
          render: (rowData) => {
            if (
              rowData["usableDevices"] &&
              rowData["usableDevices"].length > 0
            ) {
              return rowData["usableDevices"]
                .map((it) =>
                  translateService.translate({
                    i18n: JSON.parse(sessionStorage.getItem("i18n")),
                    needle: "label.form.devices.device_" + it.value,
                  })
                )
                .join(", ");
            }
          },
        },
        {
          field: "feedableGamePlatforms",
          render: (rowData) => {
            if (
              rowData["feedableGamePlatforms"] &&
              rowData["feedableGamePlatforms"].length > 0
            ) {
              return rowData["feedableGamePlatforms"]
                .map((it) => it.name)
                .join(", ");
            }
          },
        },
        {
          field: "feedableGameCodes",
          render: (rowData) => {
            if (
              rowData["feedableGameCodes"] &&
              rowData["feedableGameCodes"].length > 0
            ) {
              return rowData["feedableGameCodes"]
                .map((it) => it.name)
                .join(", ");
            }
          },
        },
        {
          field: "feedableGameFamilies",
          render: (rowData) => {
            if (
              rowData["feedableGameFamilies"] &&
              rowData["feedableGameFamilies"].length > 0
            ) {
              return rowData["feedableGameFamilies"]
                .map((it) => it.name)
                .join(", ");
            }
          },
        },
        {
          field: "feedableDevices",
          render: (rowData) => {
            if (
              rowData["feedableDevices"] &&
              rowData["feedableDevices"].length > 0
            ) {
              return rowData["feedableDevices"].map((it) => it.name).join(", ");
            }
          },
        },
        {
          field: "conversionDestinationGameDomain.name",
          title: rewardPrefix + "conversionDestinationGameDomain",
        },
        {
          field: "feedableOnlyCmp",
          render: (rowData) => {
            return <Translate needle={`label.${rowData["feedableOnlyCmp"]}`} />;
          },
        },
        {
          field: "toGive",
          title: rewardPrefix + "earned",
          render: (rowData) => {
            if (rowData["toGive"]) {
              return (
                <Currency
                  currency={campaignCurrency}
                  amount={rowData["toGive"]}
                />
              );
            }
          },
        },
        {
          field: "toPay",
          render: (rowData) => {
            if (rowData["toPay"]) {
              return (
                <Currency
                  currency={campaignCurrency}
                  amount={rowData["toPay"]}
                />
              );
            }
          },
        },
        {
          field: "optIn",
          render: (rowData) => {
            return <Translate needle={`label.${rowData["optIn"]}`} />;
          },
        },
        {
          field: "reedemable",
          render: (rowData) => {
            return <Translate needle={`label.${rowData["reedemable"]}`} />;
          },
        },
        {
          field: "usable",
          render: (rowData) => {
            return <Translate needle={`label.${rowData["usable"]}`} />;
          },
        },
        {
          field: "validityDateType.name",
          title: rewardPrefix + "validityDateType",
          render: (rowData) => {
            if (rowData["validityDateType"]) {
              return (
                <Translate
                  needle={`label.bulkoperationadd.${rowData["validityDateType"].value}`}
                />
              );
            }
          },
        },
        {
          field: "validityDays",
          render: (rowData) => {
            if (rowData["validityDays"]) {
              return <FormattedNumber value={rowData["validityDays"]} />;
            }
          },
        },
        {
          field: "validityHours",
          render: (rowData) => {
            if (rowData["validityHours"]) {
              return <FormattedNumber value={rowData["validityHours"]} />;
            }
          },
        },
        {
          field: "expirationDate",
          title: rewardPrefix + "validityEndDate",
          render: (rowData) => {
            if (rowData["expirationDate"]) {
              return <Moment format="L LT">{rowData["expirationDate"]}</Moment>;
            }
          },
        },
      ];

      const cash = [{ field: "reason", title: rewardPrefix + "description" }];

      const freeSpin = [
        { field: "description" },
        {
          field: "activationDate",
          title: rewardPrefix + "validityStartDate",
          render: (rowData) => {
            if (rowData["activationDate"]) {
              return <Moment format="L LT">{rowData["activationDate"]}</Moment>;
            }
          },
        },
        {
          field: "expirationDate",
          title: rewardPrefix + "validityEndDate",
          render: (rowData) => {
            if (rowData["expirationDate"]) {
              return <Moment format="L LT">{rowData["expirationDate"]}</Moment>;
            }
          },
        },
        { field: "gamePlatform.name", title: rewardPrefix + "gameCode" },
        { field: "gameCode.name", title: rewardPrefix + "gameType" },
        {
          field: "amountSingleFreeSpin",
          render: (rowData) => {
            if (rowData["amountSingleFreeSpin"]) {
              return (
                <Currency
                  currency={campaignCurrency}
                  amount={rowData["amountSingleFreeSpin"]}
                />
              );
            }
          },
        },
        { field: "bbTemplate.name", title: rewardPrefix + "template" },
        { field: "bbMultiplier", title: rewardPrefix + "multiplier" },
        { field: "bbValidityDays", title: rewardPrefix + "validityDays" },
      ];

      const freeTicket = [
        { field: "name" },
        { field: "description" },
        { field: "granularity" },
        {
          field: "activationDate",
          title: rewardPrefix + "validityStartDate",
          render: (rowData) => {
            if (rowData["activationDate"]) {
              return <Moment format="L LT">{rowData["activationDate"]}</Moment>;
            }
          },
        },
        {
          field: "expirationDate",
          title: rewardPrefix + "validityEndDate",
          render: (rowData) => {
            if (rowData["expirationDate"]) {
              return <Moment format="L LT">{rowData["expirationDate"]}</Moment>;
            }
          },
        },
        {
          field: "gamePlatforms",
          title: rewardPrefix + "gameType",
          render: (rowData) => {
            if (
              rowData["gamePlatforms"] &&
              rowData["gamePlatforms"].length > 0
            ) {
              return rowData["gamePlatforms"].map((it) => it.name).join(", ");
            }
          },
        },
        {
          field: "gameCodes",
          title: rewardPrefix + "gameCode",
          render: (rowData) => {
            if (rowData["gameCodes"] && rowData["gameCodes"].length > 0) {
              return rowData["gameCodes"].map((it) => it.name).join(", ");
            }
          },
        },
        { field: "chargeTo" },
        { field: "divisible" },
        { field: "exactValue" },
        { field: "sitAndGo" },
        { field: "scheduled" },
      ];

      const loyaltyPoint = [
        { field: "loyaltyDomain.name", title: rewardPrefix + "gameDomain" },
        { field: "reason", title: rewardPrefix + "description" },
      ];

      const eboBonus = [
        { field: "gameDomainId.name", title: rewardPrefix + "gameType" },
        { field: "reason", title: rewardPrefix + "description" },
      ];

      const tournamentCredits = [
        { field: "reason", title: rewardPrefix + "description" },
      ];

      const ewlBonusCredit = [
        {
          field: "operationId.name",
          title: rewardPrefix + "operationId",
          render: (rowData) => {
            if (rowData["operationId"]) {
              return (
                <Translate
                  needle={`label.rewardsForm.operation_${rowData["operationId"].value}`}
                />
              );
            }
          },
        },
        { field: "reason", title: rewardPrefix + "description" },
      ];

      const ewlCashCredit = [
        {
          field: "operationId.name",
          title: rewardPrefix + "operationId",
          render: (rowData) => {
            if (rowData["operationId"]) {
              return (
                <Translate
                  needle={`label.rewardsForm.operation_${rowData["operationId"].value}`}
                />
              );
            }
          },
        },
        { field: "reason", title: rewardPrefix + "description" },
      ];

      const voucherCredit = [{ field: "templateName" }];

      switch (rewardType) {
        case "BONUS_BAG": {
          return bonusBag;
        }
        case "CASH": {
          return cash;
        }
        case "FREE_SPIN": {
          return freeSpin;
        }
        case "FREE_TICKET": {
          return freeTicket;
        }
        case "LOYALTY_POINT": {
          return loyaltyPoint;
        }
        case "EBO_BONUS": {
          return eboBonus;
        }
        case "TOURNAMENT_CREDITS": {
          return tournamentCredits;
        }
        case "EWL_BONUS_CREDIT": {
          return ewlBonusCredit;
        }
        case "EWL_CASH_CREDIT": {
          return ewlCashCredit;
        }
        case "MERCHANDISE_MONEY_VOUCHER":
        case "MERCHANDISE_LOYALTY_VOUCHER":
        case "DISCOUNT_MONEY_VOUCHER":
        case "DISCOUNT_LOYALTY_VOUCHER": {
          return voucherCredit;
        }
        default: {
          return [];
        }
      }
    },
    createFieldByList: (data, currency, locale, divisorAmount, labelprefix) => {
      if (data != null) {
        return data.reduce((accumulator, it) => {
          const prefix = it.htmlOperator != null ? it.htmlOperator + " " : "";
          accumulator[labelprefix + it.name] =
            prefix +
            it.listValues
              .map((listValue) =>
                getFieldByType(
                  listValue.value || listValue,
                  it.backofficeType,
                  currency,
                  locale,
                  divisorAmount
                )
              )
              .join(", ");
          return accumulator;
        }, {});
      } else {
        return [];
      }
    },
    rewardsTable: {
      loading: true,
      data: [],
      columns: (currencyPrecision) => {
        return [
          {
            field: "gameAccount",
            title: labelPrefix + "campaignDetails.gameAccount",
            default: true,
          },
          {
            field: "transactionId",
            title: labelPrefix + "campaignDetails.transactionId",
          },
          {
            field: "campaignCurrency",
            title: labelPrefix + "campaignDetails.campaignCurrency",
            render: (rowData) => {
              if (rowData["campaignCurrency"]) {
                return (
                  <CurrencyBadge
                    currency={rowData["campaignCurrency"]}
                    justify={"center"}
                  />
                );
              }
            },
          },
          {
            field: "campaignCurrencyAmount",
            title: labelPrefix + "campaignDetails.campaignCurrencyAmount",
            render: (rowData) => {
              if (rowData["campaignCurrencyAmount"]) {
                return (
                  <Currency
                    currency={rowData["campaignCurrency"]}
                    amount={rowData["campaignCurrencyAmount"]}
                    justify={"flex-end"}
                    currencyPrecision={currencyPrecision}
                  />
                );
              }
            },
            default: true,
          },
          {
            field: "playerCurrency",
            title: labelPrefix + "campaignDetails.playerCurrency",
            render: (rowData) => {
              if (rowData["playerCurrency"]) {
                return (
                  <CurrencyBadge
                    currency={rowData["playerCurrency"]}
                    justify={"center"}
                  />
                );
              }
            },
          },
          {
            field: "playerCurrencyAmount",
            title: labelPrefix + "campaignDetails.playerCurrencyAmount",
            render: (rowData) => {
              if (rowData["playerCurrencyAmount"]) {
                return (
                  <Currency
                    currency={rowData["playerCurrency"]}
                    amount={rowData["playerCurrencyAmount"]}
                    justify={"flex-end"}
                    currencyPrecision={currencyPrecision}
                  />
                );
              }
            },
          },
          {
            field: "rewardDate",
            title: labelPrefix + "campaignDetails.rewardDate",
            render: (rowData) => {
              if (rowData["rewardDate"]) {
                return <Moment format="L LT">{rowData["rewardDate"]}</Moment>;
              }
            },
            getExportValueFn: (rowData) => {
              if (rowData["rewardDate"]) {
                return moment(rowData["rewardDate"]).format("L LT");
              }
            },
          },
          {
            field: "lastResultCode",
            title: labelPrefix + "campaignDetails.lastResultCode",
          },
          {
            field: "lastResultDescripion",
            title: labelPrefix + "campaignDetails.lastResultDescripion",
          },
        ];
      },
    },
    promoCodesTable: {
      loading: true,
      data: [],
      columns: (status) => {
        let commonColumns = [
          {
            field: "gameAccount",
            title: labelPrefix + "campaignDetails.gameAccountPromoCode",
            defaut: true,
          },
          {
            field: "promoCodeId",
            title: labelPrefix + "campaignDetails.promoCodeId",
          },
          {
            field: "reservationSystemId",
            title: labelPrefix + "campaignDetails.reservationSystemId",
          },
          {
            field: "status",
            title: labelPrefix + "status",
            render: (rowData) => {
              if (rowData["status"]) {
                const statusMapping = getStatusMapping(rowData["status"]);
                return (
                  <BadgeStatus
                    id={statusMapping.id}
                    label={statusMapping.label}
                  />
                );
              }
            },
            default: true,
          },
          {
            field: "reserveTransactionId",
            title: labelPrefix + "campaignDetails.reserveTransactionId",
          },
          {
            field: "reservationDate",
            title: labelPrefix + "campaignDetails.reservationDate",
            render: (rowData) => {
              if (rowData["reservationDate"]) {
                return (
                  <Moment format="L LT">{rowData["reservationDate"]}</Moment>
                );
              }
            },
            getExportValueFn: (rowData) => {
              if (rowData["reservationDate"]) {
                return moment(rowData["reservationDate"]).format("L LT");
              }
            },
          },
        ];

        const confirmedColumns = [
          {
            field: "confirmTransactionId",
            title: labelPrefix + "campaignDetails.confirmTransactionId",
          },
          {
            field: "confirmationDate",
            title: labelPrefix + "campaignDetails.confirmationDate",
            render: (rowData) => {
              if (rowData["confirmationDate"]) {
                return (
                  <Moment format="L LT">{rowData["confirmationDate"]}</Moment>
                );
              }
            },
            getExportValueFn: (rowData) => {
              if (rowData["confirmationDate"]) {
                return moment(rowData["confirmationDate"]).format("L LT");
              }
            },
          },
        ];

        const voidedColumns = [
          {
            field: "voidTransactionId",
            title: labelPrefix + "campaignDetails.voidTransactionId",
          },
          {
            field: "rollbackDate",
            title: labelPrefix + "campaignDetails.rollbackDate",
            render: (rowData) => {
              if (rowData["rollbackDate"]) {
                return <Moment format="L LT">{rowData["rollbackDate"]}</Moment>;
              }
            },
            getExportValueFn: (rowData) => {
              if (rowData["rollbackDate"]) {
                return moment(rowData["rollbackDate"]).format("L LT");
              }
            },
          },
        ];

        if (status === "CONFIRMED") {
          return [...commonColumns, ...confirmedColumns];
        } else if (status === "VOIDED") {
          return [...commonColumns, ...voidedColumns];
        } else {
          return commonColumns;
        }
      },
    },
  },
};

const CampaignManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGEMENT_LIST_FILTERS_SUCCESS: {
      return {
        ...state,
        managementList: {
          ...state.managementList,
          filters: action.payload.filter,
          filtersKey: action.payload.key,
          filtersLoading: false,
        },
      };
    }
    case MANAGEMENT_LIST_FILTERS_FAILURE: {
      return {
        ...state,
        managementList: {
          ...state.managementList,
          filters: [],
          filtersLoading: false,
        },
      };
    }
    case MANAGEMENT_LIST_REQUEST: {
      return {
        ...state,
        managementList: {
          ...state.managementList,
          lastRequest: action,
          data: null,
          tableLoading: true,
        },
      };
    }
    case MANAGEMENT_LIST_SUCCESS: {
      return {
        ...state,
        managementList: {
          ...state.managementList,
          data: action.payload,
          tableLoading: false,
        },
      };
    }
    case MANAGEMENT_LIST_FAILURE: {
      return {
        ...state,
        managementList: {
          ...state.managementList,
          data: null,
          tableLoading: false,
        },
      };
    }

    case CAMPAIGN_DETAILS_REQUEST: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          data: [],
          loading: true,
        },
      };
    }

    case CAMPAIGN_DETAILS_SUCCESS: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          data: action.payload,
          loading: false,
        },
      };
    }

    case CAMPAIGN_DETAILS_FAILURE: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          data: null,
          loading: false,
        },
      };
    }

    case REF_DETAILS_REQUEST:
    case BUDGET_DETAILS_REQUEST: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          rewardsTable: {
            ...state.campaignDetails.rewardsTable,
            loading: true,
            data: [],
          },
        },
      };
    }

    case REWARDS_DETAILS_SUCCESS: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          rewardsTable: {
            ...state.campaignDetails.rewardsTable,
            loading: false,
            data: action.payload,
          },
        },
      };
    }

    case REWARDS_DETAILS_FAILURE: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          rewardsTable: {
            ...state.campaignDetails.rewardsTable,
            loading: false,
            data: null,
          },
        },
      };
    }

    case PROMO_CODES_DETAILS_REQUEST: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          promoCodesTable: {
            ...state.campaignDetails.promoCodesTable,
            loading: true,
            data: [],
          },
        },
      };
    }

    case PROMO_CODES_DETAILS_SUCCESS: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          promoCodesTable: {
            ...state.campaignDetails.promoCodesTable,
            data: action.payload,
            loading: false,
          },
        },
      };
    }

    case PROMO_CODES_DETAILS_FAILURE: {
      return {
        ...state,
        campaignDetails: {
          ...state.campaignDetails,
          promoCodesTable: {
            ...state.campaignDetails.promoCodesTable,
            data: null,
            loading: false,
          },
        },
      };
    }
    case CAMPAIGN_REWARD_TYPES_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          rewardTypes: action.payload,
        },
      };
    }
    case CAMPAIGN_REWARD_EVENT_TYPES_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          rewardEventTypes: action.payload,
        },
      };
    }
    case CAMPAIGN_REWARD_BUDGET_TYPES_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          budgetTypes: action.payload,
        },
      };
    }
    case CAMPAIGN_REWARD_EXCLUDED_PLAYERS_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          excludedPlayers: action.payload,
        },
      };
    }

    case CAMPAIGN_TAGS_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          tags: action.payload,
        },
      };
    }

    case CAMPAIGN_PARAMETER_TYPE_OPERATOR_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          parameterOperatorMap: action.payload,
        },
      };
    }

    case CAMPAIGN_PARAMETER_DEFINITIONS_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          parameterDefinitions: action.payload,
        },
      };
    }

    case CAMPAIGN_REWARD_RULE_REQUEST: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          rewardRules: [],
        },
      };
    }

    case CAMPAIGN_REWARD_RULE_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          rewardRules: action.payload,
        },
      };
    }

    case CAMPAIGN_ACCEPTANCE_TYPE_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          acceptanceTypes: action.payload,
        },
      };
    }

    case CAMPAIGN_COMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          communications: action.payload,
        },
      };
    }
    case CAMPAIGN_FULL_DETAILS_REQUEST: {
      return {
        ...state,
        campaignEdit: {
          ...state.campaignEdit,
          loading: true,
          data: {},
        },
      };
    }

    case CAMPAIGN_FULL_DETAILS_SUCCESS: {
      return {
        ...state,
        campaignEdit: {
          ...state.campaignEdit,
          data: action.payload,
          loading: false,
        },
      };
    }
    case CAMPAIGN_CUSTOM_REWARD_SUCCESS: {
      return {
        ...state,
        managementForm: {
          ...state.managementForm,
          customRewardDefinitions: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default CampaignManagementReducer;
