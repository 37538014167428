import { takeLatest } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  CREATE_PROGRAM_FAILURE,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_SUCCESS,
  EDIT_PROGRAM_FAILURE,
  EDIT_PROGRAM_REQUEST,
  ENABLE_DISABLE_LEVEL_FAILURE,
  ENABLE_DISABLE_LEVEL_REQUEST,
  ENABLE_DISABLE_LEVEL_SUCCESS,
  ENABLE_DISABLE_PRIZE_REQUEST,
  GRANULARITY_TYPE_FAILURE,
  GRANULARITY_TYPE_INFO_FAILURE,
  GRANULARITY_TYPE_INFO_REQUEST,
  GRANULARITY_TYPE_INFO_SUCCESS,
  GRANULARITY_TYPE_REQUEST,
  GRANULARITY_TYPE_SUCCESS,
  PROGRAM_DETAIL_FAILURE,
  PROGRAM_DETAIL_REQUEST,
  PROGRAM_DETAIL_SUCCESS,
  PROGRAMS_FAILURE,
  PROGRAMS_REQUEST,
  PROGRAMS_SUCCESS,
  QUALIFICATION_PERIOD_FAILURE,
  QUALIFICATION_PERIOD_REQUEST,
  QUALIFICATION_PERIOD_SUCCESS,
  CANCEL_PROGRAM_REQUEST,
  CANCEL_PROGRAM_SUCCESS,
  CANCEL_PROGRAM_FAILURE,
  ACTIVATE_PROGRAM_REQUEST,
  ACTIVATE_PROGRAM_SUCCESS,
  ACTIVATE_PROGRAM_FAILURE,
  SUSPEND_PROGRAM_REQUEST,
  SUSPEND_PROGRAM_SUCCESS,
  SUSPEND_PROGRAM_FAILURE,
} from "../../../constants";
import programsService from "./programs-service";

const getPrograms = function* (action) {
  try {
    const data = yield call(
      programsService.getPrograms,
      action.status,
      action.currencyType,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: PROGRAMS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: PROGRAMS_FAILURE,
    });
  }
};

const createProgram = function* (action) {
  try {
    const data = yield call(
      programsService.createProgram,
      action.body,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: CREATE_PROGRAM_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("createLoyProgram", e);
    yield put({
      type: CREATE_PROGRAM_FAILURE,
    });
  }
};

const getProgramDetail = function* (action) {
  try {
    const data = yield call(
      programsService.getProgramDetail,
      action.programId,
      action.brandId,
      action.partnerId
    );

    if (data.levelGroups) {
      data.levelGroups.forEach((lg) => {
        lg.levelGranularityInfo = lg.levelGranularityId
          ? lg.levelGranularityId.split(",").map((it, i) => {
              var names = lg.levelGranularityName.split(",");
              return {
                value: it,
                label: names[i],
              };
            })
          : [];
      });
    }

    yield put({
      type: PROGRAM_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("loyProgram", e);
    yield put({
      type: PROGRAM_DETAIL_FAILURE,
    });
  }
};

const editProgram = function* (action) {
  try {
    yield call(
      programsService.editProgram,
      action.body,
      action.programId,
      action.brandId,
      action.partnerId
    );

    action.cb != null && typeof action.cb === "function" && action.cb(true);
  } catch (e) {
    action.cb != null && typeof action.cb === "function" && action.cb(false);
    console.error("editProgram", e);
    yield put({
      type: EDIT_PROGRAM_FAILURE,
    });
  }
};

const cancelProgram = function* (action) {
  try {
    yield call(
      programsService.cancelProgram,
      action.body,
      action.programId,
      action.brandId,
      action.partnerId
    );
    //Refresh Programs list to see updated status of the current program
    yield put({
      type: PROGRAMS_REQUEST,
      currencyType: action.body.currencyType,
      brand: action.brandId,
      partner: action.partnerId,
    });

    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("cancelProgram", e);
    yield put({
      type: CANCEL_PROGRAM_FAILURE,
    });
  }
};

const activateProgram = function* (action) {
  try {
    yield call(
      programsService.activateProgram,
      action.body,
      action.programId,
      action.brandId,
      action.partnerId
    );
    //Refresh Programs list to see updated status of the current program
    yield put({
      type: PROGRAMS_REQUEST,
      currencyType: action.body.currencyType,
      brand: action.brandId,
      partner: action.partnerId,
    });

    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("activateProgram", e);
    yield put({
      type: ACTIVATE_PROGRAM_FAILURE,
    });
  }
};

const suspendProgram = function* (action) {
  try {
    yield call(
      programsService.suspendProgram,
      action.body,
      action.programId,
      action.brandId,
      action.partnerId
    );
    //Refresh Programs list to see updated status of the current program
    yield put({
      type: PROGRAMS_REQUEST,
      currencyType: action.body.currencyType,
      brand: action.brandId,
      partner: action.partnerId,
    });

    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("suspendProgram", e);
    yield put({
      type: SUSPEND_PROGRAM_FAILURE,
    });
  }
};

const getGranularityType = function* (action) {
  try {
    const data = yield call(
      programsService.getGranularityType,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: GRANULARITY_TYPE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getGranularityType", e);
    yield put({
      type: GRANULARITY_TYPE_FAILURE,
    });
  }
};

const getGranularityTypeInfo = function* (action) {
  try {
    const data = yield call(
      programsService.getGranularityTypeInfo,
      action.granularityType,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: GRANULARITY_TYPE_INFO_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getGranularityTypeInfo", e);
    yield put({
      type: GRANULARITY_TYPE_INFO_FAILURE,
    });
  }
};

const getQualificationPeriod = function* (action) {
  try {
    const data = yield call(
      programsService.getQualificationPeriod,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: QUALIFICATION_PERIOD_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("getQualificationPeriod", e);
    yield put({
      type: QUALIFICATION_PERIOD_FAILURE,
    });
  }
};

const enableDisableLevel = function* (action) {
  try {
    const data = yield call(
      programsService.enableDisableLevel,
      action.programId,
      action.levelGroupName,
      action.levelName,
      action.enabled,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: ENABLE_DISABLE_LEVEL_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("enableDisableLevel", e);
    yield put({
      type: ENABLE_DISABLE_LEVEL_FAILURE,
    });
  }
};

const enableDisablePrize = function* (action) {
  try {
    const data = yield call(
      programsService.enableDisablePrize,
      action.programId,
      action.levelGroupName,
      action.levelName,
      action.prizeName,
      action.enabled,
      action.brandId,
      action.partnerId
    );

    yield put({
      type: ENABLE_DISABLE_LEVEL_SUCCESS,
      payload: data,
    });
    action.cb != null && typeof action.cb === "function" && action.cb();
  } catch (e) {
    console.error("enableDisablePrize", e);
    yield put({
      type: ENABLE_DISABLE_LEVEL_FAILURE,
    });
  }
};

const ProgramsSaga = function* () {
  yield takeLatest(PROGRAMS_REQUEST, getPrograms);
  yield takeLatest(CREATE_PROGRAM_REQUEST, createProgram);
  yield takeLatest(PROGRAM_DETAIL_REQUEST, getProgramDetail);
  yield takeLatest(EDIT_PROGRAM_REQUEST, editProgram);
  yield takeLatest(GRANULARITY_TYPE_REQUEST, getGranularityType);
  yield takeLatest(GRANULARITY_TYPE_INFO_REQUEST, getGranularityTypeInfo);
  yield takeLatest(QUALIFICATION_PERIOD_REQUEST, getQualificationPeriod);
  yield takeLatest(ENABLE_DISABLE_LEVEL_REQUEST, enableDisableLevel);
  yield takeLatest(CANCEL_PROGRAM_REQUEST, cancelProgram);
  yield takeLatest(ACTIVATE_PROGRAM_REQUEST, activateProgram);
  yield takeLatest(SUSPEND_PROGRAM_REQUEST, suspendProgram);
  yield takeLatest(ENABLE_DISABLE_PRIZE_REQUEST, enableDisablePrize);
};

export default ProgramsSaga;
