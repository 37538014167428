import { takeEvery } from "@redux-saga/core/effects";
import { call, put } from "redux-saga/effects";
import {
  PLAYER_ACLSERVICE_FAILURE,
  PLAYER_ACLSERVICE_REQUEST,
  PLAYER_ACLSERVICE_SUCCESS,
  UPDATE_PLAYER_ACLSERVICE_FAILURE,
  UPDATE_PLAYER_ACLSERVICE_REQUEST,
  UPDATE_PLAYER_ACLSERVICE_SUCCESS,
} from "../../../constants";
import playerACLservicesService from "./player-ACLservice-service";
import playerDetailService from "../player-detail/player-detail-service";
import { enqueueSnackbar } from "../../../snackbars/actions";

const loadPlayerACLservices = function* (action) {
  try {
    const data = yield call(
      playerDetailService.getPlayerDetail,
      action.playerId,
      action.brandId,
      action.partnerId,
      true
    );
    yield put({
      type: PLAYER_ACLSERVICE_SUCCESS,
      payload: data?.playerServiceParametersGroups.filter((it) => {
        return it.type == 5021;
      }),
    });
  } catch (e) {
    console.error("playerPreferences", e);
    yield put({
      type: PLAYER_ACLSERVICE_FAILURE,
    });
  }
};

const updatePlayerACLservices = function* (action) {
  try {
    const data = yield call(
      playerACLservicesService.updatePlayerACLservices,
      action.brandId,
      action.partnerId,
      action.body
    );
    yield put({
      type: UPDATE_PLAYER_ACLSERVICE_SUCCESS,
      payload: data,
    });
    yield put({
      type: PLAYER_ACLSERVICE_REQUEST,
      playerId: action.playerId,
      brandId: action.brandId,
      partnerId: action.partnerId,
      isPam: true,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.player.playerACLservice.updateSuccess",
        },
        options: {
          key: "updatePlayerACLservice",
          variant: "success",
          autoHideDuration: 2000,
        },
      })
    );
  } catch (e) {
    console.error("playerACLservices", e);
    yield put({
      type: UPDATE_PLAYER_ACLSERVICE_FAILURE,
    });
  }
};

const PlayerACLserviceSaga = function* PlayerACLserviceSaga() {
  yield takeEvery(PLAYER_ACLSERVICE_REQUEST, loadPlayerACLservices);
  yield takeEvery(UPDATE_PLAYER_ACLSERVICE_REQUEST, updatePlayerACLservices);
};

export default PlayerACLserviceSaga;
