import * as Yup from "yup";
import moment from "moment";
import React from "react";
import Translate from "../Translator/Translate";

export const isFieldHidden = (field, values) => {
  return (
    field.hidden != null &&
    typeof field.hidden === "function" &&
    field.hidden(values)
  );
};

export const isSectionHidden = (section, values) => {
  return (
    section.hidden != null &&
    typeof section.hidden === "function" &&
    section.hidden(values)
  );
};

export const isFieldDisabled = (field, values, index) => {
  return field.disabled != null && typeof field.disabled === "function"
    ? field.disabled(values, index)
    : field.disabled;
};

export const isFieldOptional = (field, values) => {
  return field.optional != null && typeof field.optional === "function"
    ? field.optional(values)
    : field.optional;
};

export const isValuesEmpty = (values) => {
  return Object.values(values).filter((it) => !isValueEmpty(it)).length === 0;
};

export const isValueEmpty = (value) => {
  if (value != null && value.constructor.name === "Object")
    return isValuesEmpty(value);
  if (Array.isArray(value)) return value.length === 0;
  return value == null || value === "" || value === false;
};

export const isDateType = (type) => {
  return ["DatePicker", "DateTimePicker", "TimePicker", "Anniversary"].includes(
    type
  );
};

export const getFieldInitialValue = (field, data) => {
  if (data != null && data[field.name] != null) return data[field.name];
  if (field.initialValue != null) return field.initialValue;
  if (field.type === "Checkbox") return false;
  if (field.type === "Switch") return false;
  if (field.type === "Select" && field.multiple) return [];
  if (field.type === "Conditional")
    return { operatorName: "", value: getFieldInitialValue(field.field) };
  if (isDateType(field.type)) return null;
  return "";
};

export const getInitialValues = (fields, data) => {
  return Object.values(fields).reduce((accumulator, field) => {
    if (field.name.includes(".")) {
      let el = accumulator;
      let d = data;
      field.name.split(".").forEach((path, index, arr) => {
        if (el[path] == null) {
          el[path] =
            index === arr.length - 1
              ? getFieldInitialValue({ ...field, name: path }, d)
              : {};
        }
        el = el[path];
        d = d ? d[path] : d;
      });
    } else {
      accumulator[field.name] = getFieldInitialValue(field, data);
    }
    return accumulator;
  }, {});
};

export const getValidationForConditional = (labelPrefix, field) => {
  return Yup.object({
    operatorName: getValidationByType("Select"),
    value: getValidation(labelPrefix, field),
  });
};

export const getspaceAndInvalidCharCheckValidation = (validation) => {
  if (validation.matches != null) {
    return validation.matches(
      new RegExp("^([a-zA-Z0-9_]+)$"),
      <Translate needle="errors.invalidCharacter" />
    );
  }
  return validation;
};

export const pattern = new RegExp("^([\x20-\x7E £€¥]+)$");

export const getValidationByType = (type) => {
  switch (type) {
    case "Switch":
    case "Checkbox": {
      return Yup.bool();
    }
    case "Text":
    case "Password": {
      return Yup.string().nullable();
    }
    case "DateTimePicker":
    case "DatePicker":
    case "TimePicker":
    case "Anniversary": {
      const validation = Yup.date().transform(function (v, originalvalue) {
        if (this.isType(v)) return v;
        return moment(originalvalue).toDate();
      });
      return validation
        .typeError(<Translate needle="errors.validDate" />)
        .nullable();
    }
    case "Integer": {
      return Yup.number();
    }
    case "Amount":
    case "Select":
    case "AutoComplete":
    case "MultipleRange":
    case "Radio": {
      return Yup.string();
    }
    case "Decimal": {
      return Yup.number();
    }
    case "Object": {
      return Yup.object();
    }
    default: {
      return Yup.string();
    }
  }
};

export const getRequiredValidation = (
  validation,
  type,
  labelPrefix,
  fieldName
) => {
  const message =
    type === "Select"
      ? "errors.requiredFieldSelect"
      : type === "Checkbox"
      ? "errors.requiredCheckbox"
      : //      : "errors.requiredField";
      type === "Object"
      ? "errors.requiredFieldSelect"
      : "errors.requiredField";
  const requiredMessage = (
    <Translate needle={message} variables={[labelPrefix + fieldName]} />
  );
  if (type === "Checkbox") {
    validation = validation.oneOf([true], requiredMessage);
  } else {
    validation = validation.required(requiredMessage);
  }
  return validation;
};

export const getMaxLengthValidation = (validation, maxLength) => {
  return validation.max(
    maxLength,
    <Translate needle="errors.maxLength" variables={[maxLength + ""]} />
  );
};

export const getMinLengthValidation = (validation, minLength) => {
  return validation.min(
    minLength,
    <Translate needle="errors.minLength" variables={[minLength + ""]} />
  );
};

export const passwordValidation = (passwordComplexity, passwordMinLength) => {
  return Yup.string()
    .min(
      passwordMinLength,
      <Translate
        needle="ko.user.changePassword.error801"
        variables={[passwordMinLength + ""]}
      />
    )
    .test(
      "password-complexity",
      <Translate
        needle="ko.user.changePassword.error802"
        variables={[passwordComplexity + ""]}
      />,
      (value) => {
        if (!value) return false;

        const hasLower = /[a-z]/.test(value);
        const hasUpper = /[A-Z]/.test(value);
        const hasNumber = /\d/.test(value);
        const hasSpecial = /[#*&%\+\-<>]/.test(value);

        const numCategories = [
          hasLower,
          hasUpper,
          hasNumber,
          hasSpecial,
        ].filter(Boolean).length;

        return numCategories >= passwordComplexity;
      }
    )
    .required(<Translate needle="errors.newPasswordRequired" />);
};

export const getPatternValidation = (
  validation,
  pattern,
  labelPrefix,
  fieldName,
  errorMessage
) => {
  const msg = errorMessage || (
    <Translate
      needle="errors.invalidPattern"
      variables={[labelPrefix + fieldName]}
    />
  );

  if (validation.matches != null) {
    return validation.matches(new RegExp(pattern), msg);
  }
  return validation;
};

export const getAfterDateValidation = (validation, beforeField) => {
  return validation.min(Yup.ref(beforeField), ({ min }) => {
    return (
      <Translate
        needle={"errors.minDate"}
        variables={[moment(min).format("L LT")]}
      />
    );
  });
};

export const getEmailValidation = (validation) => {
  if (validation.matches != null) {
    return validation.matches(
      new RegExp(
        "^[\\w\\-]{1,}([\\w\\-\\+.]{1,1}[\\w\\-]{1,}){0,}[@][\\w\\-]{1,}([.]([\\w\\-]{1,})){1,3}$"
      ),
      <Translate needle="errors.invalidEmail" />
    );
  }
  return validation;
};

export const getCharacterCheckValidation = (validation) => {
  if (validation.matches != null) {
    return validation.matches(
      pattern,
      <Translate needle="errors.invalidCharacters" />
    );
  }
  return validation;
};

export const getPositiveValidation = (validation) => {
  return validation.positive(
    <Translate needle="errors.genericGreaterThenZero" />
  );
};

export const getValidation = (labelPrefix, it) => {
  if (it.validation) return it.validation;
  let validation = getValidationByType(it.type);

  if (!it.optional) {
    validation = getRequiredValidation(
      validation,
      it.type,
      labelPrefix,
      it.name
    );
  }
  if (it.maxLength > 0) {
    validation = getMaxLengthValidation(validation, it.maxLength);
  }
  if (it.minLength > 0) {
    validation = getMinLengthValidation(validation, it.minLength);
  }
  if (it.pattern != null) {
    validation = getPatternValidation(
      validation,
      it.pattern,
      labelPrefix,
      it.name
    );
  }

  if (it.email) {
    validation = getEmailValidation(validation);
  }
  if (it.positive) {
    validation = getPositiveValidation(validation);
  }

  return validation;
};

const getRecursiveObjectSchema = (obj, dependencies) => {
  return Object.entries(obj).reduce((acc, [k, it]) => {
    const isObj = it["obj"];
    if (isObj) {
      delete it["obj"];
    }
    acc[k] = isObj
      ? Yup.object().shape(
          getRecursiveObjectSchema(
            it,
            dependencies ? dependencies[k] : undefined
          ),
          dependencies ? dependencies[k] : undefined
        )
      : it;
    return acc;
  }, {});
};

export const getValidationSchema = (labelPrefix, formFields, dependencies) => {
  const nestedObjectSchema = Object.values(formFields)
    .filter((it) => it.name.includes("."))
    .reduce((accumulator, field) => {
      field.name.split(".").reduce((o, i, index, array) => {
        o[i] =
          o[i] ||
          (index === array.length - 1
            ? getValidation(labelPrefix, field)
            : { obj: true });
        return o[i];
      }, accumulator);
      return accumulator;
    }, {});

  const flatObjectSchema = Object.values(formFields)
    .filter((it) => !it.name.includes("."))
    .reduce((accumulator, field) => {
      accumulator[field.name] = getValidation(labelPrefix, field);
      return accumulator;
    }, {});
  return Yup.object().shape(
    {
      ...flatObjectSchema,
      ...getRecursiveObjectSchema(nestedObjectSchema, dependencies),
    },
    dependencies ? dependencies.global : undefined
  );
};
