import {
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  LANGUAGES_ACTION,
  LANGUAGES_REQUEST,
  PLAYER_LANGUAGES_REQUEST,
  PLAYER_LANGUAGES_SUCCESS,
} from "../constants";
import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";

const getLanguages = async () => {
  try {
    const response = await axios.get("/gov/api/rest/v2/languages");
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const languages = function* (action) {
  const data = yield call(getLanguages, action.payload);
  yield put({
    type: LANGUAGES_ACTION,
    payload: data,
  });
};

const fetchPlayerLanguages = async () => {
  try {
    const response = await axios.get("/gov/api/rest/v1/smsconsole/languages");
    let enabledLangs = [];
    response.data.forEach((l) => {
      if (l.enabled) {
        enabledLangs.push(l);
      }
    });
    return enabledLangs;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const fetchCountries = async (brand, partner, enabled) => {
  try {
    const response = await axios.get("/gov/api/rest/v1/countries", {
      params: {
        brandCode: brand.id,
        partnerCode: partner.id,
        showEnabled: enabled,
      },
    });
    return response.data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getPlayerLanguages = function* (action) {
  const data = yield call(fetchPlayerLanguages, action.payload);
  yield put({
    type: PLAYER_LANGUAGES_SUCCESS,
    payload: data,
  });
};

const getCountries = function* (action) {
  const data = yield call(
    fetchCountries,
    action.brand,
    action.partner,
    action.onlyEnabled
  );
  yield put({
    type: COUNTRIES_SUCCESS,
    payload: data,
  });
};

const LanguageSaga = function* languageSaga() {
  yield takeEvery(LANGUAGES_REQUEST, languages);
  yield takeEvery(PLAYER_LANGUAGES_REQUEST, getPlayerLanguages);
  yield takeEvery(COUNTRIES_REQUEST, getCountries);
};

export default LanguageSaga;
