import React from "react";
import {
  PLAYER_ACLSERVICE_FAILURE,
  PLAYER_ACLSERVICE_REQUEST,
  PLAYER_ACLSERVICE_SUCCESS,
  UPDATE_PLAYER_ACLSERVICE_FAILURE,
  UPDATE_PLAYER_ACLSERVICE_REQUEST,
  UPDATE_PLAYER_ACLSERVICE_SUCCESS,
} from "../../../constants";
import YesNoBadge from "../../../../Layouts/BadgeStatus/yes-no-badge";
import { Box } from "@material-ui/core";

export const prefix = "label.player.playerACLservice.";

const initialState = {
  playerACLservicePanelList: {
    loading: true,
    data: null,
    fields: (widget, t, playerACLserviceData) => {
      const listPanelFields = [];
      if (playerACLserviceData && playerACLserviceData.length > 0) {
        playerACLserviceData[0].attributes
          .sort((a, b) => a.attributeId - b.attributeId)
          .map((el) => {
            if (el) {
              let eachField = {
                field: el.attributeId.toString(),
                name: el.attributeId.toString(),
                type: "Switch",
                titleTooltip: t({
                  needle: prefix + "toolTip." + el.attributeId,
                }),
                render: (rowData) => {
                  return (
                    <Box
                      display="flex"
                      flexDirection="column"
                      flexWrap={"wrap"}
                      justifyContent={"left"}
                      style={{
                        margin: "0 -8px",
                      }}
                    >
                      <YesNoBadge value={el.attributeValue} />
                    </Box>
                  );
                },
              };
              listPanelFields.push(eachField);
            }
          });
      }
      return listPanelFields;
    },
  },
};

const PlayerACLserviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_ACLSERVICE_REQUEST: {
      return {
        ...state,
        playerACLservicePanelList: {
          ...state.playerACLservicePanelList,
          lastRequest: action,
          loading: true,
          playerACLserviceData: null,
        },
      };
    }

    case PLAYER_ACLSERVICE_SUCCESS: {
      return {
        ...state,
        playerACLservicePanelList: {
          ...state.playerACLservicePanelList,
          loading: false,
          playerACLserviceData: action.payload,
        },
      };
    }

    case PLAYER_ACLSERVICE_FAILURE: {
      return {
        ...state,
        playerACLservicePanelList: {
          ...state.playerACLservicePanelList,
          loading: false,
        },
      };
    }

    case UPDATE_PLAYER_ACLSERVICE_REQUEST: {
      return {
        ...state,
        playerACLservicePanelList: {
          ...state.playerACLservicePanelList,
          loading: true,
        },
      };
    }
    case UPDATE_PLAYER_ACLSERVICE_SUCCESS: {
      return {
        ...state,
        playerACLservicePanelList: {
          ...state.playerACLservicePanelList,
          loading: false,
          playerACLserviceData: action.payload,
        },
      };
    }
    case UPDATE_PLAYER_ACLSERVICE_FAILURE: {
      return {
        ...state,
        playerACLservicePanelList: {
          ...state.playerACLservicePanelList,
          loading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default PlayerACLserviceReducer;
